.blue-menu {
	display: flex;
	// height: 48px;
	background-color: #004182;
	align-items: stretch;
	flex-wrap: nowrap;
}
.blue-menu__item {
	width: 100%;
	color: #FFF;
	line-height: 20px;
	font-size: rem(15);
	font-weight: bold;
	text-transform: uppercase;
	justify-content: center;
	text-align: center;
	padding: 14px 0;
	display: flex;
	align-items: center;
	&:hover, &--active {
		background-color: $bright-cyan;
		color: #FFF;
	}
	&--big {
		font-size: 21px;
		line-height: 25px;
	}
}
.blue-menu--offset-top {
	margin-top: -24px;
	position: relative;
	z-index: 3;
	margin-bottom: 40px;
}