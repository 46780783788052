.download-list {

}
.download-list__item {
	border-bottom: 1px solid #e5e5e5;
	display: block;
	color: $black;
	position: relative;
	padding: 20px 85px 20px 0;
	&:hover {
		color: $pinkish-red;
		.download-list__icon {
			fill: $pinkish-red;
		}
	}
	&:last-child {
		border-bottom: 0;
	}
}
.download-list__icon {
	width: 13px;
	height: 16px;
	fill: #b7cbe0;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(-38px, -50%)
}
@media (max-width: 767px) {
	.download-list__item {
		padding: 20px 45px 20px 0;
	}
	.download-list__icon {
		transform: translate(-15px, -50%)
	}
}