.gallery-item {
  margin-bottom: 47px;
  &--inside {
    margin-bottom: 4px;
  }
}
.gallery-item__img {
  display: block;
  margin-bottom: 20px;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
.gallery-item__title {
  color: #2e2d2c;
  font-size: 18px;
  font-weight: bold;
}