.open-day-block {
  overflow: hidden;
  width: 100%;
  border: 6px solid #fff;
  padding: 50px 70px;
  font-weight: 700;
  margin-top: 100px;
}
.open-day-block__date {
  float: left;
  margin-right: 25px;
}
.open-day-block__num {
  font-size: rem(56);
  line-height: 58px;
}
.open-day-block__month {
  font-size: rem(24);
}
.open-day-block__label {
  overflow: hidden;
  font-size: rem(18);
}
.decorated-title--faculty1 {.open-day-block {background-color: #004182;}}
.decorated-title--faculty2 {.open-day-block {background-color: #fb7318;}}
.decorated-title--faculty3 {.open-day-block {background-color: #fba918;}}
.decorated-title--faculty4 {.open-day-block {background-color: #8f5ba5;}}
.decorated-title--faculty5 {.open-day-block {background-color: #3688c7;}}
.decorated-title--faculty6 {.open-day-block {background-color: #221e1f;}}
.decorated-title--faculty7 {.open-day-block {background-color: #001882;}}
.decorated-title--faculty8 {.open-day-block {background-color: #6b26a0;}}
.decorated-title--faculty9 {.open-day-block {background-color: #30a460;}}
.decorated-title--faculty10 {.open-day-block {background-color: #d54c4b;}}
.decorated-title--faculty11 {.open-day-block {background-color: #44c8f5;}}