.sorting {
	font-size: 0;
	line-height: 0;
	margin-bottom: 15px;
}
.sorting__label {
	font-weight: bold;
	font-size: rem(16);
	margin-right: 7px;
}
.sorting__item {
	font-size: rem(16);
	margin-right: 15px;
	color: $black;
	&:hover, &--active {
		color: $pinkish-red-two;
		.sorting__direction--up {
			border-color: transparent transparent $pinkish-red-two transparent;
		}
		.sorting__direction--down {
			border-color: $pinkish-red-two transparent transparent transparent;
		}
	}
}
.sorting__direction {
	vertical-align: 12px;
	margin-left: 7px;
	width: 0;
	height: 0;
	border-style: solid;
}
.sorting__direction--up {
	border-width: 0 4px 6px 4px;
	border-color: transparent transparent $cool-grey transparent;
}
.sorting__direction--down {
	vertical-align: -14px;
	border-width: 6px 4px 0 4px;
	border-color: $cool-grey transparent transparent transparent;
}