.share {
  margin-top: 20px;
}
.share__title {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}
.share__links {
  font-size: 0;
  display: inline-block;
  vertical-align: 6px;
}
.share__item {
  display: inline-block;
  margin-right: 5px;
  width: 25px;
  height: 25px;
  background: #2e2d2c;
  border-radius: 3px;
  text-align: center;
  line-height: 25px;
  &:hover {
    background: #004282;
    .share__icon {
      
    }    
  }
}
.share__icon {
  fill: #FFF;
  vertical-align: middle;
  width: 14px;
  height: 14px;
}