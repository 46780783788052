.advantages-short {
	overflow: hidden;
	max-width: 1920px;
	margin: 0 auto;
}
.advantages-short__item {
	float: left;
	height: 145px;
	width: 24%;
	line-height: 145px;
	border-left: 1px #e5e5e5 solid;
	text-align: center;
	&:nth-child(2) {
		width: 22%;
	}
	&:nth-child(3) {
		width: 25%;
	}
	&:first-child {
		border-left: 0;
	}
}
.facts-numbers {
	float: right;
	height: 145px;
	width: 28%;
	padding: 24px;
	background-color: $bright-cyan;
	span {
		display: inline-block;
		width: 100%;
		height: 96px;
		line-height: 96px;
		text-align: center;
		border: solid 1px #bdebfb;
		color: #FFF;
		font-weight: bold;
	}
	svg {
		margin-right: 17px;
		width: 47px;
		height: 48px;
		fill: #bdebfb;
		vertical-align: middle;
	}
	&:hover {
		background-color: $bright-cyan;
		span {
			border-color: #FFF;
		}
		svg {
			fill: #FFF;
		}
	}
}
.advantages-short__icon {
	vertical-align: middle;
	fill: #4dbce8;
	&.icon-index_adv1 {
		width: 63px;
		height: 61px;
	}
	&.icon-index_adv2 {
		width: 64px;
		height: 55px;
	}
	&.icon-index_adv3 {
		width: 61px;
		height: 58px;
	}
}
.advantages-short__name {
	font-weight: bold;
	color: $black;
	display: inline-block;
	line-height: normal;
	vertical-align: middle;
	margin-left: 18px;
	text-align: left;
}
@media (max-width: 1200px) {
	.advantages-short__item {
		height: 220px;
		line-height: normal;
		svg {
			margin: 30px 0 25px 0;
		}
	}
	.advantages-short__name {
		text-align: center;
		margin-left: 0;
	}
	.facts-numbers {
		height: 220px;
		svg {
			display: block;
			margin: 16px auto 20px;
		}
		span {
			line-height: normal;
			height: 172px;	
		}
	}
	
	
	// .icon-index_article {
	// 	margin-right: 12px;
	// }
	.mobile-line {
		display: flex;
		justify-content: center;
		.science-and-innovation__item--fill-width {
			width: auto;
		}
	}
}
@media (max-width: 767px) {
	.advantages-short__item {
		display: none;
		// float: none;
		// width: 100%;
		// height: auto;
		// text-align: left;
		// padding-left: 20px;
		// &:nth-child(2) {
		// 	width: 100%;
		// }
		// &:nth-child(3) {
		// 	width: 100%;
		// }
		// svg {
		// 	margin-right: 10px;
		// }
	}
	.advantages-short__name {
		text-align: left;
	}
	.facts-numbers {
		width: 100%;
		float: none;
		display: block;
		height: 149px;
		span {
			height: 100px;
			line-height: 100px;
		}
		svg {
			display: inline-block;
			margin: 0 13px 0 0;
		}
	}
	.icon-index_article {
		display: none;
	}
	.icon-index_hirsh {
		display: none;
	}
	.icon-index_innovation {
		width: 50px;
	height: 79px;
	}
	.icon-index_vak {
		width: 44px;
		height: 62px;
	}
	.icon-index_dissertation {
		width: 57px;
		height: 43px;
	}
	.icon-index_patent {
		width: 58px;
		height: 50px;
	}
	.mobile-line {
		display: block;
		margin-left: 12px;
	}
	.science-and-innovation__item {
		float: none;
		width: auto;
		overflow: hidden;
	}
}