.calculator__row {
	margin-bottom: 20px;
	&:after {
    content: " ";
    display: table;
    clear: both;
	}
}
.calculator__input {
	float: right;
}
.calculator__row-name {
	display: table-cell;
	vertical-align: middle;
	height: 48px;
	font-size: rem(19);
}
.calculator__row-results {
	margin-top: 20px;
	padding: 20px 0;
	border-top: solid 1px #ededed;
	border-bottom: solid 1px #ededed;
}
.calculator__subm {
	float: right;
	width: 200px;
}
.calculator__total {
	font-size: rem(18);	
	height: 50px;
	display: table-cell;
	vertical-align: middle;
}
.calculator__total-num {
	font-size: rem(20);
	font-weight: bold;
}

.calculator__history {
	margin-top: 20px;
}
.calculator__history-item {
	margin-bottom: 15px;
}
.calculator__history-label {
	overflow: hidden;
}
.calculator__circle {
	width: 16px;
	height: 16px;
	display: inline-block;
	border-radius: 50%;
	margin-right: 10px;	
	vertical-align: middle;
	float: left;
	margin-top: 5px;
}
.calculator__circle--green {
	background-color: #9bd23c;
}
.calculator__circle--yellow {
  background-color: #f3e73e;
}
.calculator__results {
	margin-bottom: 30px;
}
.calculator__result {
	display: table;
	width: 100%;
	margin-bottom: 15px;
	padding-bottom: 15px;
	color: #000;
	border-bottom: solid 1px #ededed;
}
.calculator__result-name {
	display: table-cell;
}
.calculator__result-specialty {
	font-weight: bold;
}
.calculator__result-score {
	display: table-cell;
	vertical-align: middle;
	text-align: right;
	font-weight: bold;
	width: 58px;
}