.e404-header {
	height: 166px;
	background: #004282;
	overflow: hidden;
	.logo-box {
		height: auto;
		margin: 0 auto;
		float: none;
		.logo {
			margin: 30px 0 0 0;
		}
	}
}
.e404-title {
	font-size: rem(34);
	margin-bottom: 20px;
}
.e404-note {
	font-size: rem(18);
	margin-bottom: 20px;
}
.e404-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 166px);
}
.e404-text {
	text-align: center;
	img {
		max-width: 100%;
	}
}