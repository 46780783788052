.custom-radio {
	overflow: hidden;
	position: relative;
	padding-bottom: 16px;
	input{
	  position: absolute;
	  visibility: hidden;
	  &:checked ~ label {
	  	color: $pinkish-red;
	  	&:before {
				border-color: $pinkish-red;
				background:$pinkish-red;
			}
			&:after {
				content: "";
				position: absolute;
				left: 11px;
				top: 11px;
				width: 8px;
				height: 8px;
				border-radius: 4px;
				background: #FFF;
				transform: translate(-50%, -50%);
			}
	  }
	}
	label {
		display: inline-block;
		position: relative;
		padding-left: 35px;
		cursor: pointer;
		font-weight: normal;
		margin-bottom: 0;
			&:hover {
				color: $pinkish-red;
				&:before {
					border-color: $pinkish-red;
				}
			}
		&:before {
			content: "";
			width: 22px;
			height: 22px;
			border: 1px solid $cool-grey;
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 11px;
		}
	}
}
.custom-radio--without-text {
	font-size: 0;
	line-height: 0;
	label {
		padding: 0;
		width: 24px;
		height: 24px;
	}
}
.questionnaire-radio {
	overflow: hidden;
	margin-bottom: 16px;
	input{
	  position: absolute;
	  visibility: hidden;
	  &:checked ~ label {
	  	border-color: $pinkish-red;
	  	background:$pinkish-red;
	  	color: #FFF;
	  }
	}
	label {
		margin: 0;
		font-size: rem(16);
		line-height: 45px;
		height: 48px;
		width: 204px;
		text-align: center;
		border: 1px #dcdddf solid;
		font-weight: normal;
		cursor: pointer;
		span {
			line-height: normal;
			vertical-align: middle;
			display: inline-block;
		}
			&:hover {
				color: $pinkish-red;
				border-color: $pinkish-red;
			}
	}
}
