.olympics-item {
  margin-bottom: 30px;
  p {
    margin: 5px 0;
  }
}
.olympics-item__name {
  font-weight: 700;
  font-size: rem(24);
  margin-bottom: 15px;
}
.olympics-item__postdate {
  color: #989897;

}
.olympics-item__short-text {
  
}
.olympics-item__full-text {
  display: none;
}
.olympics-item__show-full {
  font-size: rem(16);
  font-weight: 700;
  color: #0f5499;
  padding: 0;
  border: 0;
  background: none;
  appearance: none;
  &:hover, &:focus {
    outline: 0;
    color: #ec1646;
    .olympics-item__show-text {
      border-color: transparent;
    }
  }
}
.olympics-item__show-text {
  border-bottom: 1px dashed #0f5499;
}