.error {
	input[type="text"],
	textarea {
		border-color: $pinkish-red-two;
	}
	label {
		color: $pinkish-red-two;	
	}
}
.fl-row {
	margin-bottom: 20px;
	.row {
		margin-bottom: 0;
	}
}
.form-sumb {
	text-align: center;
}

.login-form {
	max-width: 480px;
	margin: 40px auto 0;
	padding: 40px;
	background: #f5f6f8;
}
.questionnaires-form {
	padding: 40px;
	background: #f5f6f8;
	.fl-row {
		margin-bottom: 40px;
	}
	.fl-row > label {
		margin-bottom: 15px;
	}
	.you-variant-fl {
		display: none;
	}
}
.form-tbl {
	width: 100%;
	th, td {
		vertical-align: middle;
		border-bottom: 1px #dcdddf solid;
		padding: 8px 5px;
		text-align: center;
		&:first-child {
			text-align: left;
		}
	}
	.custom-radio {
		padding: 0;
	}
}
@media (max-width:767px) {
	.questionnaires-form {
		padding: 20px;
		width: 100vw;
		margin-left: -12px;
		margin-bottom: 40px;
	}
}