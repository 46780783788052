select {
    width: 100%;
    height: 48px;
    background: white url(../img/arrow-down-black.svg) calc(100% - 15px) 50% no-repeat;
    background-size: 13px 7px;
    box-shadow: 0 3px 2px rgba(0, 36, 71, 0.05);
    border: 0;
    border-radius: 0;
    appearance: none;
    padding: 0 48px 0 25px;
    box-shadow: 0 3px 2px rgba(0, 36, 71, 0.05);
    outline: 0;
    font-size: rem(16);
    line-height: normal
}
@media (max-width: 767px) {
    select {
        padding: 0 48px 0 15px;
    }
}   