.breadcrumbs {
	background-color: $pale-grey;
	padding: 9px 0 4px 0;
	font-size: 0;
	&__item {
		font-size: rem(16);
		text-align: left;
		color: rgba(46, 45, 44, 0.8);
	}
	&__delimiter {
		width: 7px;
		height: 11px;
		fill: #6e6e71;
		margin: 0 14px;
	}
	&__item--active {
		color: $black;
	}
	.container {
		// overflow: hidden;
		// white-space: nowrap;
	}
}
.has-long-tbl {
	.breadcrumbs .container {
		margin: 0;
	}
}
@media (max-width: 1200px) {
	.breadcrumbs {
		display: none;
	}
}