.full-menu-open, .full-menu-open body {
    height: 100vh;
    width: 100%;
    overflow: hidden;
}
.full-menu-contauner {
    display: none;
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #265e95;
    z-index: 100;
    left: 0;
    top: 0;
    padding: 80px 0 20px 0;
    overflow: scroll;
}
.full-menu-contauner__close {
    width: 61px;
    height: 61px;
    background-color: #4d4d4f;
    border: 0;
    position: fixed;
    z-index: 101;
    top: 0;
    right: 0;
    &:before {
        content: "";
        height: 16px;
        border: 1px solid #FFF;
        position: absolute;
        transform: rotate(45deg) translate(-8px, -50%);
        top: 50%;
        left: 50%;
    }
    &:after {
        content: "";
        height: 16px;
        border: 1px solid #FFF;
        position: absolute;
        transform: rotate(-45deg) translate(8px, -50%);
        top: 50%;
        left: 50%;
    }
}
.full-menu__link {
    color: #ffffff;
    font-size: 18px;
    &:hover {
        color: #44c8f5;
    }
}
.full-menu__link--large {
    font-size: 36px;
    font-weight: 700;
    display: block;
    margin-bottom: 20px;
}

.full-menu__subrow {
    
}
.full-menu__dropdown-link {

}
.full-menu__dropdown-container {

}
.full-menu__list {
    list-style: none;
    margin: 0;
    padding: 0;
    & > li {
        margin: 7px 0;
    }
}
.full-menu__list--sublist {
    margin-left: 10px;
    li {
        position: relative;
        padding-left: 12px;
        &:before {
            content: "";
            position: absolute;
            width: 4px;
            height: 4px;
            background-color: white;
            border-radius: 50%;
            left: 0;
            top: 9px;
        }
    }
}
@media (min-width: 1201px) {
    .full-menu__row {
        border-bottom: 1px solid #35699c;
        padding-bottom: 45px;
        margin-bottom: 45px;
    }
}
@media (max-width: 1200px) {
    .full-menu__subrow {
        border-bottom: 1px solid #35699c;
        padding-bottom: 35px;
        margin-bottom: 35px;
    }
}
@media (max-width: 767px) {
    .full-menu__link--large {
        font-size: rem(24)!important;
        margin: 12px 20px;
    }
    .full-menu__subrow {
        padding-bottom: 15px;
        margin-bottom: 15px;
        padding: 0;
        margin: 0;
        & > .full-menu__dropdown-container {
            display: none;
        }
    }
    .full-menu__row, .full-menu__subrow {
        border: 0;
    }
}