.about-numbers {
	display: flex;
	justify-content:space-between;
}
.about-numbers__item {
	&:nth-child(3) {
		width: 185px;
	}
}
.about-numbers__num {
	font-size: rem(100);
	font-weight: bold;
	letter-spacing: -2px;
	line-height: 0.79;
	margin-bottom: 20px;
	&--small {
		font-size: rem(72);
	}
}
.about-numbers__text {
	font-size: rem(16);
	line-height: 1.17;
	font-style: italic;
}
@media(max-width:1200px) {

}
@media(max-width:767px) {
	.about-numbers {
		display: block;
		text-align: center;
	}
	.about-numbers__item {
		margin: 20px 0;
		&:nth-child(3) {
			width: 100%;
		}
	}
}