.decorated-title {
	min-height: 408px;
	background-color: $pale-grey;
	position: relative;
	overflow: hidden;
	h1 {
		margin-top: 48px;
	}
	.container {
		position: relative;
		z-index: 3;
	}
	&__text {
		font-size: 18px;
	}
	&--rectorat {
		.decorated-title__text {
			width: 460px;
		}
	}
	&--ege {
		margin-bottom: 45px;
		.decorated-title__text {
			width: 591px;
		}
		h1 {
			width: 591px;
		}
	}
	&--rector {
		margin-bottom: 45px;
	}
	&--documents {
		min-height: 312px;
	}
	&--structure {
		min-height: 312px;
		margin-bottom: 48px;
		form {
			margin-top: 70px;
		}
	}
	&--about {
		padding-top: 35px;
		min-height: 408px;
		margin-bottom: 47px;
		.decorated-title__text {
			width: 587px;
		}
	}
	&--univer-careers {
		min-height: 408px;
		margin-bottom: 50px;
		.decorated-title__text {
			line-height: 1.56;
			padding-bottom: 40px;
			max-width: 690px;
		}
		.decorated-title__bg--careers {
			left: 61%;
		}
	}
	&--for-students {
		padding-top: 40px;
		margin-bottom: 48px;
		.decorated-title__text {
			width: 556px;
			background-color: #f5f6f8;
		}
	}
	&--employment {
		margin-bottom: 50px;
		.decorated-title__text {
			width: 584px;
		}
		.interview {
			width: 277px;
			padding: 0;
			margin: 15px 0 0 48px;
		}
	}
	&--oporniy_vuz {
		margin-bottom: 50px;
		.decorated-title__text {
			width: 584px;
		}
	}
	&__bg {
		position: absolute;
		z-index: 2;
		left: 50%;
		top: 0;
		transform: translate(-24px, 0);
		width: 661px;
		height: 100%;
	}
	&--incoming, &--contacts, &--science {
		height: 408px;
		.decorated-title__text {
			width: 500px;
		}
		h1 {
			margin: 70px 0;
		}
	}
	&--contacts {
		height: 408px;
		.decorated-title__text {
			width: 600px;
		}
		h1 {
			margin: 60px 0 30px 0;
		}
	}
	&--business {
		margin-bottom: 40px;
		.decorated-title__text {
			width: 600px;
		}
	}
	&--faculty {
		height: 475px;
		margin-bottom: 48px;
		color: #ffffff;
		@media (max-width: 767px) {
	   height: auto;
	  }
		.decorated-title__text {
			width: 560px;
		}
		h1 {
			width: 688px;
		}
	}
	&--faculty1 {.fillet-text {background: #004182;box-shadow: -8px 0 0 1px #004182, 8px 0 0 1px #004182;padding: 1px 0;}}
	&--faculty2 {.fillet-text {background: #fb7318;box-shadow: -8px 0 0 1px #fb7318, 8px 0 0 1px #fb7318;padding: 1px 0;}}
	&--faculty3 {.fillet-text {background: #fba918;box-shadow: -8px 0 0 1px #fba918, 8px 0 0 1px #fba918;padding: 1px 0;}}
	&--faculty4 {.fillet-text {background: #8f5ba5;box-shadow: -8px 0 0 1px #8f5ba5, 8px 0 0 1px #8f5ba5;padding: 1px 0;}}
	&--faculty5 {.fillet-text {background: #3688c7;box-shadow: -8px 0 0 1px #3688c7, 8px 0 0 1px #3688c7;padding: 1px 0;}}
	&--faculty6 {.fillet-text {background: #221e1f;box-shadow: -8px 0 0 1px #221e1f, 8px 0 0 1px #221e1f;padding: 1px 0;}}
	&--faculty7 {.fillet-text {background: #001882;box-shadow: -8px 0 0 1px #001882, 8px 0 0 1px #001882;padding: 1px 0;}}
	&--faculty8 {.fillet-text {background: #6b26a0;box-shadow: -8px 0 0 1px #6b26a0, 8px 0 0 1px #6b26a0;padding: 1px 0;}}
	&--faculty9 {.fillet-text {background: #30a460;box-shadow: -8px 0 0 1px #30a460, 8px 0 0 1px #30a460;padding: 1px 0;}}
	&--faculty10 {.fillet-text {background: #d54c4b;box-shadow: -8px 0 0 1px #d54c4b, 8px 0 0 1px #d54c4b;padding: 1px 0;}}
	&--faculty11 {.fillet-text {background: #44c8f5;box-shadow: -8px 0 0 1px #44c8f5, 8px 0 0 1px #44c8f5;padding: 1px 0;}}
	&--faculty13 {.fillet-text {background: #99adb8;box-shadow: -8px 0 0 1px #99adb8, 8px 0 0 1px #99adb8;padding: 1px 0;}}
}
.decorated-title__bg--faculty {
	left: 0;
	transform: none;
	width: 100%;
}
.decorated-title__bg--faculty1 {
	background: url(../img/faculties/1.svg) 50% no-repeat;
	background-size: cover;
}
.decorated-title__bg--faculty2 {
	background: url(../img/faculties/2.svg) 50% -590px no-repeat;
	background-size: cover;
	@media (max-width: 767px) {
    background: url(../img/faculties/2.svg) 50% no-repeat;
		background-size: cover;
  }
}
.decorated-title__bg--faculty3 {
	background: url(../img/faculties/3.svg) 50% no-repeat;
	background-size: cover;
}
.decorated-title__bg--faculty4 {
	background: url(../img/faculties/4.svg) 50% no-repeat;
	background-size: cover;
}
.decorated-title__bg--faculty5 {
	background: url(../img/faculties/5.svg) 50% no-repeat;
	background-size: cover;
}
.decorated-title__bg--faculty6 {
	background: url(../img/faculties/6.svg) 50% no-repeat;
	background-size: cover;
}
.decorated-title__bg--faculty7 {
	background: url(../img/faculties/7.svg) 50% no-repeat;
	background-size: cover;
}
.decorated-title__bg--faculty8 {
	background: url(../img/faculties/8.svg) 50% no-repeat;
	background-size: cover;
}
.decorated-title__bg--faculty9 {
	background: url(../img/faculties/9.svg) 50% no-repeat;
	background-size: cover;
}
.decorated-title__bg--faculty10 {
	background: url(../img/faculties/10.svg) 50% no-repeat;
	background-size: cover;
}
.decorated-title__bg--faculty11 {
	background: #44c8f5 url(../img/faculties/11.svg) 123% -27px no-repeat;
  background-size: 820px 820px;
  @media (max-width: 767px) {
    background: #44c8f5 url(../img/faculties/11.svg) 123% -27px no-repeat;
  	background-size: 410px 410px;
  }
}
.decorated-title__bg--faculty13 {
	background: url(../img/faculties/13.svg) 50% no-repeat;
	background-size: cover;
}
.decorated-title__message {
	color: #FFF;
	font-size: rem(18);
	font-weight: bold;
	line-height: 1.33;
	width: 270px;
	margin: 101px 0 0 46px;
	&-icon {
		width: 76px;
		height: 76px;
		opacity: 0.2;
		fill: #ffffff;
		display: block;
		margin-bottom: 46px;
	}
}
.decorated-title__bg--for-students {
	background:  url(../img/decorate-title-bg-mask.png) center no-repeat, 
							 url(../img/decorate-title-for-students.png) center no-repeat;
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
		background:  url(../img/decorate-title-bg-mask@2x.png) center no-repeat, 
								 url(../img/decorate-title-for-students@2x.png) center no-repeat;
		background-size: 661px 649px,
										 255px 255px;
	}
}
.decorated-title__bg--rectorat {
	background:  url(../img/decorate-title-bg-mask.png) center no-repeat, 
							 url(../img/decorate-title-rectorat.png) center no-repeat;
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
		background:  url(../img/decorate-title-bg-mask@2x.png) center no-repeat, 
								 url(../img/decorate-title-rectorat@2x.png) center no-repeat;
		background-size: 661px 649px,
										 250px 250px;
	}
}

.decorated-title__bg--about {
	background:  url(../img/about-univer-bg.png) center no-repeat;
	width: 713px;
	height: 408px;
	transform: translate(-70px, 0);
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
		background:  url(../img/about-univer-bg@2x.png) center no-repeat;
		background-size: 713px 408px;
	}
}

.decorated-title__bg--oporniy_vuz {
	background: url(../img/mission-block-bg.png) 0 50% no-repeat;
	background-size: 232px 325px;
	transform: translate(240px, 0);
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
		background:  url(../img/mission-block-bg@2x.png) 0 50% no-repeat;
		background-size: 232px 325px;
	}
}

.decorated-title__bg--univer-careers {
	background-color: $darkish-blue;
	width: 100%;
	transform: translate(190px, 0);
}

.decorated-title__bg--employment {
	background-color: $darkish-blue;
	width: 100%;
	transform: translate(190px, 0);
}
.decorated-title__bg--contacts {
	background: url(../img/contacts-header.png) 50% 0 no-repeat;
}
.decorated-title__bg--incoming {
	background: url(../img/incoming-header.png) 100% 0 no-repeat;
}
.decorated-title__bg--ege {
	background: url(../img/mission-block-bg.png) 60% 50% no-repeat;
	background-size: 232px 325px;
}
.decorated-title__bg--business {
	background: url(../img/business-header.jpg) 80% 50% no-repeat;
}

.decorated-title__bg--careers, .decorated-title__bg--documents, .decorated-title__bg--structure, .decorated-title__bg--science {
	
	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background:  url(../img/decorate-title-bg-mask.png) center no-repeat;
		z-index: 2;
	}
	&:before {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		width: 250px;
		height: 250px;
		border-radius: 50%;
		transform: translate(-50%, -50%);
		background: #004182 url(../img/decorate-title-documents.png) center no-repeat;
	}
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
		&:after {
			background:  url(../img/decorate-title-bg-mask.png) center no-repeat;
			background-size: 661px 649px;
		}
		&:before {
			background: #004182 url(../img/decorate-title-documents@2x.png) center no-repeat;
			background-size: 81px 38px;
		}
		
	}
}
.decorated-title__bg--careers {
	&:before {
		content: "";
		background: url(../img/decorated-title-careers.jpg) center no-repeat;
	}
}

@media (max-width: 1200px) {
	.decorated-title {
		min-height: inherit;
		padding-bottom: 30px;
		overflow: hidden;
		height: auto;
	}
	.decorated-title__bg {
		//left:calc(100% - 317px);
		display: none;
	}
	.decorated-title__bg--faculty {
		display: block;
	}
	.decorated-title__text {
		width: 100%!important;
	}
	.decorated-title--incoming {
		height: auto;
	}
}

@media (max-width: 767px) {
	.decorated-title--documents {
		overflow: visible;
	}
	.decorated-title {
		h1 {
			text-align: center;
		}
	}
	.decorated-title--structure {
		// padding:0 0 300px 0;
		form {margin-top:0;}
		h1 {margin-top:15px;}
	}
	.decorated-title__bg--structure	{
		&:after {
			background:  url(../img/decorate-title-bg-mask.png) center 108px no-repeat;
			background-size: cover;
		}
		&:before {
			background: #004182 url(../img/decorate-title-documents@2x.png) center no-repeat;
			background-size: 81px 38px;
			top:73%;
			left:51%;
		}		
	}
	.decorated-title--incoming, .decorated-title--science {
		height: auto;
		h1 {
			margin: 10px 0;
		}
		.decorated-title__text {
			display: none;
		}
	}
	.decorated-title {
		h1 {
			width: auto;
		}
	}
}