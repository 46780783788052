.custom-check {
	overflow: hidden;
	position: relative;
	margin-bottom: 16px;
	input{
	  position: absolute;
	  visibility: hidden;
	  &:checked ~ label {
	  	color: $pinkish-red;
	  	&:before {
				border-color: $pinkish-red;
				background:$pinkish-red url(../img/check.png) center no-repeat;
			}
	  }
	}
	label {
		display: inline-block;
		position: relative;
		padding-left: 35px;
		cursor: pointer;
		font-weight: normal;
		margin-bottom: 0;
			&:hover {
				color: $pinkish-red;
				&:before {
					border-color: $pinkish-red;
				}
		}
		&:before {
			content: "";
			width: 22px;
			height: 22px;
			border: 1px solid $cool-grey;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}