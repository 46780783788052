.locations-widget {

}
.locations-widget__right {
  width: 332px;
  float: right;
  overflow: hidden;
  height: 750px;
  background: #f7f7f7;
  padding-bottom: 20px;
}
.locations-widget__map-container {
  overflow: hidden;
  height: 750px;
  background: #ebebeb;
}
.locations-widget__item {
  padding: 19px 10px 0 40px;
  margin-right: 30px;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #dedfe0;
    margin-top: 15px;
  }
  &:hover, &--active {
    background: #dedfe0;
    cursor: pointer;
  }
}
.locations-widget__item-name {
  font-weight: 700;
}
.locations-widget__item-info {

}
.locations-widget__list {
  overflow: hidden;
  height: 100%;
}

.location-widget-baloon {
  display: flex;
}
.location-widget-baloon__photo {
  
}
.location-widget-baloon__info {
  padding-left: 25px;
}
.location-widget-baloon__title {
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 10px;
}
.location-widget-baloon__text {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}
ymaps[class*="ground-pane"] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
  -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
}
@media (max-width: 767px) {
  .locations-widget__right, .location-widget-baloon__photo {
    display: none;
  }
  .locations-widget__map-container {
    height: 400px;
  }
}