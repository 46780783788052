.accordion {

}
.accordion--structure {
	border-top: 3px $pinkish-red-two solid;
}
.accordion__item-caption {
	overflow: hidden;
	border-bottom: solid 1px #ededed;
	display: block;
	min-height: 60px;
	line-height: 60px;
	position: relative;
	cursor: pointer;
	&--active {
		border-bottom: 0;
		font-weight: bold;
		.accordion__item-caption-icon {
			transform: translate(0, -60%) rotate(180deg);
		}
	}
}
.accordion__item-caption--structure {
	height: 88px;
	line-height: 88px;
	font-size: rem(24);
	font-weight: 700;
	border: solid 1px #ededed;
	border-top: 0;
	@media (max-width: 767px) {
    height: auto;
    font-size: rem(20);
  }
	&.accordion__item-caption--active {
		border-bottom: 0;
	}
	&.accordion__item-caption--active + .accordion__item-container--structure + .accordion__item-caption--structure {
		border-top: 3px $pinkish-red-two solid;
	}
}
.accordion__item-caption--tbl {
	font-size: rem(18);
	font-weight: 700;
	border: solid 1px #ededed;
	border-top: 0;
	&.accordion__item-caption--active {
		border-bottom: 0;
	}
	&.accordion__item-caption--active + .accordion__item-container--structure + .accordion__item-caption--structure {
		border-top: 3px $pinkish-red-two solid;
	}
}
.accordion__item-caption--substructure {
	border: 0;
	overflow: hidden;
	&:hover, &.accordion__item-caption--active {
		background-color: #f5f6f8;
	}
}

.accordion__item-container {
	display: none;
	border-bottom: solid 1px #ededed;
	padding: 0 60px 20px 25px;
	@media (max-width: 767px) {
    padding: 0 20px 20px;
  }
}
.accordion__item-container--structure {
	border: solid 1px #ededed;
	border-top: 0;
	margin-bottom: 23px;
	padding: 0;
}
.accordion__item-container--structure2 {
	padding: 20px;
}
.accordion__item-container--substructure {
	background-color: #f5f6f8;
}
.accordion__item-container--tbl {
	padding: 0;
}

.accordion__item-caption-align {
	display: inline-block;
	line-height: normal;
	vertical-align: middle;
	padding: 20px 57px 20px 25px; 
	width: 100%;
}
.accordion__item-caption-icon {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	width: 14px;
	height: 8px;
	right: 19px;
	opacity: 0.2;
	fill: #000;
}

.accordion__caption-right {
	width: 130px;
	float: right;
	margin-left: 50px;
}
.accordion__caption-left {
	overflow: hidden;
}


