.faculty-item {
  height: 204px;
  display: block;
  padding:  38px 30px 30px;
  position: relative;
  color: #fff;
  margin-bottom: 16px;
  &:hover {
    color: #ffffff;
  }
}
.faculty-item__name {
  font-size: rem(24);
  font-weight: 700;
  line-height: 32px;
}
.faculty-item__specialty {
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 173px;
  padding: 5px 0 5px 10px;
}
.faculty-item__specialty-count {
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  float: left;
  margin-right: 18px;
}
.faculty-item__specialty-label {
  font-size: rem(15);
  font-weight: 700;
  line-height: 18px;
  display: block;
  overflow: hidden;
  width: 115px;
}

.faculty-item--1 {
  background: url(../img/faculties/1.svg);
  background-size: cover;
  .faculty-item__name {
    background: #004182;
    box-shadow: -20px 0 0 1px #004182, 20px 0 0 1px #004182;
    padding: 1px 0 5px 0;
  }
  .faculty-item__specialty {
    background: #004182;
  }
}
.faculty-item--2 {
  background: url(../img/faculties/2.svg) 0 -210px no-repeat;
  background-size: cover;
  @media (max-width: 767px) {
    background: url(../img/faculties/2.svg) 0 -120px no-repeat;
    background-size: cover;
  }
  .faculty-item__name {
    background: #fb7318;
    box-shadow: -20px 0 0 1px #fb7318, 20px 0 0 1px #fb7318;
    padding: 1px 0 5px 0;
  }
  .faculty-item__specialty {
    background: #fb7318;
  }
}
.faculty-item--3 {
  background: url(../img/faculties/3.svg);
  background-size: cover;
  .faculty-item__name {
    background: #fba918;
    box-shadow: -20px 0 0 1px #fba918, 20px 0 0 1px #fba918;
    padding: 1px 0 5px 0;
  }
  .faculty-item__specialty {
    background: #fba918;
  }
}
.faculty-item--4 {
  background: url(../img/faculties/4.svg) 0 -210px no-repeat;
  background-size: cover;
  @media (max-width: 767px) {
    background: url(../img/faculties/4.svg) 0 -120px no-repeat;
    background-size: cover;
  }
  .faculty-item__name {
    background: #8f5ba5;
    box-shadow: -20px 0 0 1px #8f5ba5, 20px 0 0 1px #8f5ba5;
    padding: 1px 0 5px 0;
  }
  .faculty-item__specialty {
    background: #8f5ba5;
  }
}
.faculty-item--5 {
  background: url(../img/faculties/5.svg);
  background-size: cover;
  .faculty-item__name {
    background: #3688c7;
    box-shadow: -20px 0 0 1px #3688c7, 20px 0 0 1px #3688c7;
    padding: 1px 0 5px 0;
  }
  .faculty-item__specialty {
    background: #3688c7;
  }
}
.faculty-item--6 {
  background: url(../img/faculties/6.svg) 0 -80px no-repeat;
  background-size: cover;
  @media (max-width: 767px) {
    background: url(../img/faculties/6.svg) 0 no-repeat;
    background-size: cover;
  }
  .faculty-item__name {
    background: #221e1f;
    box-shadow: -20px 0 0 1px #221e1f, 20px 0 0 1px #221e1f;
    padding: 1px 0 5px 0;
  }
  .faculty-item__specialty {
    background: #221e1f;
  }
}
.faculty-item--7 {
  background: url(../img/faculties/7.svg) 0 -80px no-repeat;
  background-size: cover;
  @media (max-width: 767px) {
    background: url(../img/faculties/7.svg) 0 no-repeat;
    background-size: cover;
  }
  .faculty-item__name {
    background: #001882;
    box-shadow: -20px 0 0 1px #001882, 20px 0 0 1px #001882;
    padding: 1px 0 5px 0;
  }
  .faculty-item__specialty {
    background: #001882;
  }
}
.faculty-item--8 {
  background: url(../img/faculties/8.svg);
  background-size: cover;
  .faculty-item__name {
    background: #6b26a0;
    box-shadow: -20px 0 0 1px #6b26a0, 20px 0 0 1px #6b26a0;
    padding: 1px 0 5px 0;
  }
  .faculty-item__specialty {
    background: #6b26a0;
  }
}
.faculty-item--9 {
  background: url(../img/faculties/9.svg);
  background-size: cover;
  .faculty-item__name {
    background: #30a460;
    box-shadow: -20px 0 0 1px #30a460, 20px 0 0 1px #30a460;
    padding: 1px 0 5px 0;
  }
  .faculty-item__specialty {
    background: #30a460;
  }
}
.faculty-item--10 {
  background: url(../img/faculties/10.svg);
  background-size: cover;
  .faculty-item__name {
    background: #d54c4b;
    box-shadow: -20px 0 0 1px #d54c4b, 20px 0 0 1px #d54c4b;
    padding: 1px 0 5px 0;
  }
  .faculty-item__specialty {
    background: #d54c4b;
  }
}
.faculty-item--11 {
  background: #44c8f5 url(../img/faculties/11.svg) 123% -27px no-repeat;
  background-size: 380px 380px;
  .faculty-item__name {
    background: #44c8f5;
    box-shadow: -20px 0 0 1px #44c8f5, 20px 0 0 1px #44c8f5;
    padding: 1px 0 5px 0;
  }
  .faculty-item__specialty {
    background: #44c8f5;
    width: 195px;
  }
}
.faculty-item--13 {
  background: #99adb8 url(../img/faculties/13.svg);
  background-size: cover;
  .faculty-item__name {
    background: #99adb8;
    box-shadow: -20px 0 0 1px #99adb8, 20px 0 0 1px #99adb8;
    padding: 1px 0 5px 0;
  }
  .faculty-item__specialty {
    background: #99adb8;
    width: 195px;
  }
}