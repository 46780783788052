.social-links {
	font-size: 0;
	line-height: 0;
	margin: 20px 11px 0 0;
	&__item {
		float: left;
		margin-right: 10px;
		width: 21px;
		height: 21px;
		svg {
			width: 21px;
			height: 21px;
			fill: $slate-grey;
		}
		&:hover, &:focus {
			svg {
				fill: $cool-grey;
			}
		}
	}
}
.social-links--right {
	float: right;
}
.social-links--left {
	float: left;
}
.header__bottom-line {
	.social-links {
		margin-left: 25px;
	}	
}