.income-block-link {
  height: 168px;
  background: #e6e7e9;
  display: block;
  margin: 48px 0;
  line-height: 168px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 337px;
    background: url(../img/incode-block-link-mask.png) 100% 50% no-repeat, url(../img/incode-block-link-ege.png) 57% 50% no-repeat;
    z-index: 2;
  }
}
.income-block-link--step {
  &:after {
    background: url(../img/incode-block-link-mask.png) 100% 50% no-repeat, url(../img/incode-block-link-step.png) 57% 50% no-repeat;
  }
}
.income-block-link--house {
  &:after {
    background: url(../img/incode-block-link-mask.png) 100% 50% no-repeat, url(../img/incode-block-link-house.png) 57% 50% no-repeat;
  }
}
.income-block-link--usa {
  &:after {
    background: url(../img/incode-block-link-mask.png) 100% 50% no-repeat, url(../img/incode-block-link-usa.png) 57% 50% no-repeat;
  }
}
.income-block-link--soc {
  &:after {
    background: url(../img/incode-block-link-mask.png) 100% 50% no-repeat, url(../img/incode-block-link-soc.png) 57% 50% no-repeat;
  }
}
.income-block-link--voen {
  &:after {
    background: url(../img/incode-block-link-mask.png) 100% 50% no-repeat, url(../img/incode-block-link-voen.png) 57% 50% no-repeat;
  }
}
.income-block-link--blue {
  background-color: #004282;
  .income-block-link__text {
    color: #ffffff;
  }
}

.income-block-link--science {
  background: #004282 url(../img/faculties/12.svg);
  background-size: 880px 420px;
  background-position: 0 0;
  line-height: normal;
  display: flex;
  align-items: center;
  &:after {
    display: none;
  }
  .income-block-link__text {
    span {
      color: #ffffff;
      display: inline;
      line-height: normal;
      background: #004282;
      -webkit-box-shadow: -20px 0 0 1px #004282, 20px 0 0 1px #004282;
      box-shadow: -20px 0 0 1px #004282, 20px 0 0 1px #004282;
      padding: 1px 0 5px 0;
    }
  }
}

.income-block-link__text {
  position: relative;
  z-index: 3;
  font-size: rem(30);
  font-weight: 700;
  line-height: 38px;
  width: 280px;
  display: inline-block;
  margin-left: 48px;
  vertical-align: middle;
  color: #2e2d2c;
}