.main-menu {
	list-style: none;
	font-size: 0;
	line-height: 0;
	float: left;
	padding: 0;
}
.main-menu__sub-menu {
	list-style: none;
	display: none;
	position: absolute;
	left: 0;
	top: 100%;
	background-color: $dusk-blue;
	width: 250px;
	padding: 5px 30px;
	z-index: 10;
}
.main-menu__item {
	display: inline-block;
	height: 60px;
	position: relative;
		&:hover {
			.main-menu__sub-menu {
				display: block;
			}
			.main-menu__link {
				color: $light-navy;
				&:after {
					content: "";
					left: 0;
					bottom: 0;
					position: absolute;
					height: 3px;
					width: 100%;
					background-color: $pinkish-red-two;
				}
			}
			.main-menu__link--hassub {
				color: #FFF;
				background-color: $dusk-blue;
				&:after {
					display: none;
				}
			}
		}
}
.main-menu__link {
	padding: 0 23px;
	display: block;
	width: 100%;
	height: 60px;
	line-height: 60px;
	color: $black;
	font-size: rem(15);
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 0.6px;
	text-align: center;
	position: relative;
}

.main-menu__subitem {
	display: block;
	margin: 15px 0;
}
.main-menu__sublink {
	font-size: rem(16);
	font-weight: 400;
	line-height: normal;
	color: #FFF;
	&:hover {
		color: $bright-cyan;
	}
}