.questions {
	h1 {
		float: left;
	}
}
.question {
	padding: 48px 88px 56px 64px; 
	background-color: $pale-grey;
	margin-bottom: 24px;
	&__post-date {
		color: $cement;
	}
	&__text {
		margin: 10px 0;
	}
	&__autor {
		font-weight: bold;
	}
	&__answer {
		margin-top: 30px;
		padding-top: 25px;
		border-top: 1px #e1e2e3 solid;
		font-style: italic;
	}
	&__company {
		font-size: rem(24);
		font-weight: bold;
		margin-bottom: 18px;
	}
	&__autor-post {
		color: $cement;
		font-style: italic;
		margin-top: 10px;
	}
}
.question--preview {
	padding: 50px 50px 45px 50px;
}
.question__title {
	margin-top: 0;
}
.question__to-all {
	display: inline-block;
	font-size: rem(15);
	font-weight: bold;
	letter-spacing: 0.6px;
	color: $light-navy;
	text-transform: uppercase;
	margin-top: 25px;
}
@media(max-width:767px){
	.question {
		padding: 15px;
	}
	.questions h1 {
		float: none;
	}
	.show-question-form {
		float: none;
		margin-top: 20px;
		margin-bottom: 20px;
		width: 100%;
	}
}