.footer {
	height: 350px;
	background: url(../img/footer-bg.png);
	margin-top: 100px;
	color: #FFF;
	padding-top: 47px;
	&--nopd {
		margin-top: 0;
	}
	&__container {
		width: 1220px;
		margin: 0 auto;
		overflow: hidden;
		position: relative;
	}
	&__contacts {
		font-size: rem(14);
		margin-bottom: 40px;
	}
	&__committee {
		font-size: rem(14);
		b {
			display: block;
			font-size: rem(16);
		}
	}
	.logo {
		display: block;
		width: 140px;
		height: 65px;
		background: url(../img/logo.svg);
	}
}
.footer-menu {
	&__item {
		font-size: rem(16);
		color: #FFF;
		display: block;
		margin-bottom: 22px;
		&--big {
			text-transform: uppercase;
			font-weight: bold;
			font-size: rem(15);
		}
		&:hover {
			color: $pinkish-red-two;
		}
	}
}
.footer-col {
	float: left;
	margin-right: 22px;
	&:last-child {
		margin-right: 0;
	}
	&--2 {
		width: 153px;
	}
	&--3 {
		width: 243px;
	}
}
.copy-w {
	font-size: rem(14);
	margin-top: 163px;
}
.devepoler-mob {
	display:none;
}
.has-long-tbl {
	.footer__container {
		margin: 0 0 0 calc((100vw - 1220px) / 2);
	}
}
@media (max-width: 1200px) {
	.footer {
		height: 220px;
	}
	.footer__container {
		width: 90%;
		margin: 0 auto;
	}
	.footer-col--3 {
		width: auto;
		overflow: hidden;
		float: none;
	}
	.footer__contacts {
		float: left;
		margin-right: 10%;
	}
	.copy-w {
		margin-top: 53px;
	}
}	
@media (max-width: 767px) {
	.devepoler {
		display:none;
	}
	.devepoler-mob {
		display:block;
		width:100%;
		text-align:center;
		margin:15px 0;
		font-size: 13px;
		font-family: "Open Sans",sans-serif;
		.dev {float:none;margin:auto;}
	}	
	.footer {
		margin-top: 50px;
		background-size: cover;
		height: auto;
		padding-top: 20px;
		padding-bottom: 20px;
		.logo {
			display: none;
		}
		&--nopd {
			margin-top: 0;
		}
	}
	.footer-col--2,.footer-col--3 {
		float: none;
		width:100%;
		text-align:center;
	}	
	.copy-w {
		margin-top: 0;
		margin-bottom: 10px;
	}
	.footer__contacts {
		margin-bottom: 20px;
		float: none;
		margin-right:0;
	}
	.footer-col--3 .social-links {
		width: 100%;
		text-align: center;
		margin:20px 0 0 0;
		float:left;
		.social-links__item {float:none;}
	}
}