.ege-bonus-item {
  overflow: hidden;  
  padding: 48px 0;
  border-bottom: 1px solid #e5e5e5;
  &__img {
    float: left;
    margin: 0 40px 0 0;
  }
  &:last-child {
    border-bottom: 0;
  }
  &:nth-child(odd) {
    .ege-bonus-item__img {
      float: right;
      margin: 0 0 0 40px;
    }
  }
}
.ege-bonus-item__img {
  
}
.ege-bonus-item__info {
  overflow: hidden;
}
.ege-bonus-item__name {
  font-size: rem(34);
  font-weight: 700;
  line-height: 38px;
}
.ege-bonus-item__text {
  font-size: rem(15);
  margin: 10px 0;
}
.ege-bonus-item__points {
  font-size: rem(22);
  font-weight: 700;
}

.ege-allowance {

}
.ege-allowance__item {
  display: flex;
  font-weight: 700;
  margin-bottom: 48px;
  align-items: stretch;
}
.ege-allowance__item-num {
  font-size: rem(34);
  line-height: normal;
  flex: 0 0 44px;
}
.ege-allowance__item-name {
  font-size: rem(15);
  line-height: 24px;
}
.ege-allowance__item-points {
  font-weight: 400;
  font-size: rem(15);
  flex: 0 0 195px;
}
.ege-allowance__item-allowance {
  margin-left: auto;
  font-size: rem(14);
  text-transform: uppercase;
  flex: 0 0 120px;
  border-left: 1px #edeeef solid;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span {
    vertical-align: middle;
  }
}

.ege-allowance-note {
  font-weight: 700;
  color: #FFF;
  text-align: center;
  padding: 30px 60px;
  background-color: #44c8f5;
}

@media (max-width: 1200px) {
  .ege-allowance__item-points {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  .ege-bonus-item:nth-child(odd) .ege-bonus-item__img, .ege-bonus-item__img {
    float: none;
    margin: 0 auto 40px;
    display: block;
  }
  .ege-allowance__item {
    flex-wrap: wrap;
    .ege-allowance__item-name {
      flex: 1 1;
    }
    .ege-allowance__item-allowance {
      flex: 0 0 100px;
    }
  }
  .ege-allowance-note {
    padding:  20px;
  }
}