.important-information {
	background-color: $darkish-blue;
	padding: 25px 24px 52px 24px;
	background-color: $pale-grey;
	margin-bottom: 58px;
	&--t0 {
		padding-top: 0;
	}
}
.important-information__title {
	margin: 0 0 23px 0;
}
.important-information__list {
	
}
.important-information__item {
	// padding-bottom: 23px;
	// margin-bottom: 23px;

	// border-bottom: 1px $silver solid;
	// height: 220px;
	// &:last-child {
	// 	border-bottom: 0;
	// 	margin-bottom: 0;
	// }
}
.important-information__post-date {
	color: $cement;
	margin-bottom: 15px;
}
.important-information__name {
	color: $black;
	font-weight: bold;
	font-size: rem(18);
	&--regular {
		font-weight: normal;
	}
}
.important-information__arrow {
	padding: 0;
	width: 48px;
	height: 48px;
	border: 0;
	background: #2e2d2c;
	position: absolute;
	bottom: -52px;
	z-index: 10;
	outline: 0;
	left: 50%;
	svg {
		width: 9px;
		height: 14px;
		fill: #FFF;
	}
	&:hover {
		background: #4d4d4f;
	}
}
.important-information__arrow--prev {
	margin-left: -48px;
	svg {
		transform: rotate(90deg);
	}
}
.important-information__arrow--next {
	svg {
		transform: rotate(90deg);
	}
}

