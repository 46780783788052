.faculty-roadmap {

}
.faculty-roadmap__step {

}
.faculty-roadmap__step-title {
  display: flex;
  align-items: center;
}
.faculty-roadmap__learning-period {
  width: 115px;
  height: 115px;
  border-radius: 50%;
  text-align: center;
  font-size: rem(14);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  
  &--blue {
    background: #033a7c;
  }
  &--red {
    background: #e2003b;
  }
  &--cyan {
    background: #5cc5f2;
  }
}
.faculty-roadmap__learning-period-label {
  line-height: normal;
}
.faculty-roadmap__period-num {
  font-size: rem(46);
  font-weight: 700;
  display: block;
  margin-top: 14px;
}
.faculty-roadmap__period-units {
  font-size: rem(16);
  
}

.faculty-roadmap__step-name {
  
  font-size: rem(46);
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 40px;
  flex: 0 1;
  &--blue {
    color: #033a7c;
  }
  &--red {
    color: #e2003b;
  }
  &--cyan {
    color: #5cc5f2;
  }
}
.faculty-roadmap__items {
  margin-top: 4px;
  padding-top: 26px;
  overflow: hidden;
  margin-bottom: 4px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 54px;
    top: 0;
    width: 4px;
    height: 26px;
    background: #003b7a;
    z-index: 2;
  }
}
.faculty-roadmap__item {
  display: flex;
  padding-left:112px;
  padding-bottom: 26px;
  font-size: rem(24);
  position: relative;
  &--last {
    &:after {
      display: none;
    }
  }
  &:last-child {
    padding-bottom: 15px;
  }
  &:before {
    content: "";
    position: absolute;
    left: 44px;
    top: 0;
    width: 24px;
    height: 24px;
    background: #ffffff;
    border: 5px solid #003b7a;
    box-shadow: 0 0 0 4px #FFF;
    border-radius: 50%;
    z-index: 3;
  }
  &:after {
    content: "";
    position: absolute;
    left: 54px;
    top: 0;
    width: 4px;
    height: 100%;
    background: #003b7a;
    z-index: 2;
  }
}
.faculty-roadmap__item-number {
  margin-right: 30px;
  color: #3b3b39;
}
.faculty-roadmap__item-name {
  color: #0b3a79;
  text-transform: uppercase;
  font-weight: bold;
}

@media (max-width: 765px) {
  .faculty-roadmap__step-title {
    flex-wrap: wrap;
  }
  
  .faculty-roadmap__step-name { 
    margin-left: 10px;
    font-size: 30px;
    width: 100%;
    margin-top: 15px;
  }
  .faculty-roadmap__item {
    padding-left: 52px;
    &:before {
      left: 20px;
    }
    &:after {
      left: 30px;
    }
    &:last-child{
      &:after {
        display: none;
      }
    }
  }
  .faculty-roadmap__item, .faculty-roadmap__item-number {
    display: block;
  }
  .faculty-roadmap__items {
    padding-top: 15px;
    &:before {
      display: none;
      
    }
  } 
}