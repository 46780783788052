.incoming-menu {
  height: 280px;
  background: url(../img/incoming-menu.jpg) center no-repeat;
  margin: 0 auto 47px;
  overflow: hidden;
}
.incoming-menu__row {
  display: flex;
  align-content: center;
  border-bottom: 1px solid #183a5a;
  &:last-child {
    border-bottom: 0;
  }
}
.incoming-menu__link {
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  height: 140px;
  line-height: 140px;
  text-align: center;
  flex: 1;
  border-left: 1px solid #183a5a;
  &:first-child {
    border-left: 0;
    text-align: right;
    span {
      width: 155px;
      margin-right: 90px;
    }
  }
  &:last-child {
    text-align: left;
    span {
      width: 155px;
      margin-left: 90px;
    }
  }
  &--center {
    flex: 0 0 357px;
  }
  span {
    display: inline-block;
    line-height: 22px;
    vertical-align: middle;
    text-align: center;
  }
    &:hover {
      background: rgba(68, 200, 245, .5);
      color: #ffffff;
    }
}
@media (max-width: 1200px) {

  .incoming-menu__link--center {
    flex: 1;
  }
  .incoming-menu__link {
    &:first-child {
      text-align: center;
      span {
        width: auto;
        margin-right: 0px;
      }
    }
    &:last-child {
      text-align: center;
      span {
         width: auto;
        margin-left: 0px;
      }
    }
  }
}
@media (max-width: 767px) {
  .incoming-menu__row {
    display: block;
    border: 0;
  }
  .incoming-menu__link {
    display: block;
    height: auto;
    line-height: normal;
    padding: 20px;
    border: 0;
    border-bottom: 1px solid #183a5a;
    br {
      display: none;
    }
  }
  .incoming-menu {
    height: auto;
    background-size: cover;
  }
}