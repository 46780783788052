.search-bt {
	padding: 0;
	border-radius: 0;
	border: 0;
	width: 23px;
	height: 23px;
	float: right;
	margin: 19px 20px 0 0;
	background: none;
	outline: none;
	svg {
		width: 23px;
		height: 23px;
		fill: $slate-grey;
	}
	&:hover, &:focus {
		svg {
			fill: $cool-grey;
		}
	}
	&--left {
		float: left;
	}
}