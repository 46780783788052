.large-decorated-link {
	display: block;
	height: 168px;
	margin-bottom: 20px;
	line-height: 168px;
	font-size: rem(36);
	font-weight: bold;
	// letter-spacing: -1.8px;
	color: #ffffff;
	padding-left: 47px;
	&:hover {
		color: #FFF;
		opacity: .8;
	}
	&__align {
		display: inline-block;
		line-height: normal;
		vertical-align: middle;
		line-height: rem(38);
	}
	&--students {
		background: $pinkish-red-two url(../img/questionnaires-for-students-bg.png) 100% 50% no-repeat;
		@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
			background: $pinkish-red-two url(../img/questionnaires-for-students-bg@2x.png) 100% 50% no-repeat;
			background-size: 325px 168px;
		}
	}
	&--employer {
		background: $darkish-blue url(../img/questionnaires-for-employer-bg.png) 100% 50% no-repeat;
		@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
			background: $darkish-blue url(../img/questionnaires-for-employer-bg@2x.png) 100% 50% no-repeat;
			background-size: 317px 168px;
		}
	}
	&--schedule {
		background: $bright-cyan url(../img/large-link-schedule-bg.png) 100% 50% no-repeat;
		@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
			background: $bright-cyan url(../img/large-link-schedule-bg@2x.png) 100% 50% no-repeat;
			background-size: 330px 168px;
		}
	}
	&--more-vacancies {
		margin-bottom: 25px;
		background: $pinkish-red-two url(../img/large-link-schedule-bg.png) 100% 50% no-repeat;
		@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
			background: $pinkish-red-two url(../img/large-link-schedule-bg@2x.png) 100% 50% no-repeat;
			background-size: 330px 168px;
		}	
	}
	&--more-summary {
		margin-bottom: 25px;
		background: $darkish-blue url(../img/large-link-schedule-bg.png) 100% 50% no-repeat;
		@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
			background: $darkish-blue url(../img/large-link-schedule-bg@2x.png) 100% 50% no-repeat;
			background-size: 330px 168px;
		}	
	}
	&__big {
		font-weight: bold;
		font-size: rem(60);
		line-height: rem(48);
		display: inline-block;
	}
	&__small {
		font-weight: normal;
		font-size: rem(20);
		line-height: rem(20);
		display: inline-block;
	}

}
@media (max-width: 767px) {
	.large-decorated-link {
		&--more-vacancies, &--more-summary {
			background-position: calc(100vw - 150px) 50%;
		}
		&--students, &--employer, &--schedule {
			background-position: calc(100vw - 130px) 50%;
			font-size: rem(28);
			padding-left: 15px;
		}
	}
}