.header {
	height: 120px;
	background-image: linear-gradient(to bottom, $darkish-blue 0%,$darkish-blue 100%), linear-gradient(to bottom, $black 0%,$black 60px,#ffffff 60px,#ffffff 120px);
	background-repeat: no-repeat, no-repeat;
	background-position: 0 0, 50% 0;
	background-size: 45% 120px, 100% 120px;
}
.header__container {
	max-width: 1220px;
	width: 100%;
	margin: 0 auto;
}
.header__right {
	
}
.header__top-line {
	overflow: hidden;
	height: 60px;
	background-color: $black;
}
.header__bottom-line {
	background: #FFF;
	height: 60px;
	width: 100%;
}
.mobile-header {
	height: 74px;
	background-color: #004282;
	position: relative;
	.scale-font {
		float: left;
		margin-left: 20px;
		margin-top: 26px;
		svg {
			fill: #FFF;
		}
	}
	.show-full-menu {
		outline: none;
		margin: 21px 32px 0 0;
		svg {
			fill: #FFF;
		}
	}
}
.has-long-tbl {
	.header__container {
		margin: 0 0 0 calc((100vw - 1220px) / 2);
	}
}
@media (max-width: 1200px) {
  	.header {
		height: 120px;
	}
	.header__bottom-line {
		height: 60px;	
		padding-right: 32px;
	}
}	