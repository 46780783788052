.contacts-block {
	color: #FFF;
	background-color: $bright-cyan;
	padding: 50px;
	margin-bottom: 40px;
	.icon {
		float: left;
		margin-right: 11px;
		margin-top: 5px;
		fill: #2285bb;
	}
	.icon-location {
		width: 12px;
		height: 15px;
	}
	.icon-telefone {
		width: 12px;
		height: 14px;
	}
	.icon-fax {
		width: 12px;
		height: 12px;
	}
	.icon-mail {
		width: 13px;
		height: 9px;
		margin-top: 8px;
	}
	.icon-link {
		width: 14px;
    height: 16px;
	}
	.icon-vk-social {
		width: 12px;
		height: 7px;
		margin-top: 9px;
	}
	.icon-user {
		width: 14px;
		height: 17px;
	}
	a {
		color: $light-navy;
	}
}
.contacts-block__item {
	margin-bottom: 10px;
}
.contacts-block__title {
	margin-top: 0;
}
.contacts-block__text {
	overflow: hidden;
}
.contacts-block__social {
	text-align: center;
	font-size: 0;
	margin-top: 20px;
}
.contacts-block__social-item {
	width: 38px;
	height: 38px;
	line-height: 35px;
	text-align: center;
	display: inline-block;
	border: 1px #33a7d9 solid;
	border-radius: 50%;
	margin: 0 5px;
	svg {
		vertical-align: middle;
		fill: #2285bb;
	}
	.icon-rector-fb {
		width: 9px;
		height: 16px;
	}
	.icon-rector-twitter {
		width: 16px;
		height: 13px;
	}
	.icon-vk-social {
		width: 16px;
		height: 9px;
		margin-top: 2px;
	}
	&:hover {
		border-color: #2b90c9;
		svg {
			fill: #0f5499;
		}
	}
}
@media (max-width:767px) {
	.contacts-block {
		padding: 20px;
	}	
}