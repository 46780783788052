.calendar  * {
  box-sizing: border-box;
  font-size: rem(14);
}
.calendar-sm {
  cursor: default;
  width: 800px;
  height: 370px;
}
.calendar {
  color: $navy;
  cursor: default;
  width: 290px;
  height: 265px;
  position: absolute;
  top: 50%;
  left: 66px;
  transform: translate(0, -50%);
}

.calendar-sm .c-pad-top {
  padding-top: 2%;
}


.c-grid {
  
  height: inherit;
}
.c-day, .c-day-previous-month, .c-day-next-month {
  width: 32px;
  height: 32px;
  line-height: 32px;
  float: left;
  text-align: center;
  font-size: 13px;
  margin: 0 4px;
}
.c-day {
  opacity: .5;
  border-radius: 16px;
}
.c-today {
  border: solid 1px #cbcccd;
  
}
.c-day-previous-month {
  span {
    font-size: 0;
  }
}

.c-day-next-month {
  font-size: 0;
}

.c-week-day {
  width: 32px;
  height: 32px;
  line-height: 32px;
  float: left;
  font-size: rem(12);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: 0 4px;
}

.c-next {
  width: 12.5%;
  height: 12%;
  padding: 2% 2% 0 2%;
  text-align: right;
  cursor: pointer;
}

.c-previous {
  width: 12.5%;
  padding: 2% 2% 0 2%;
  text-align: left;
  cursor: pointer;
}

.c-month {
  width: 75%;
  height: 12%;
  text-align: center;
}

.c-event {
  cursor: pointer;
  opacity: 1;
  font-weight: bold;
  &:hover, &.c-event-active {
    background-color: $pinkish-red;
    color: #FFF;
  }
}
.c-grid {
  width: 100%;
  // background: #FFF;
}

.c-grid-title {
  float: left;
  height: 43px;
  line-height: 43px;
  font-size: rem(15);
  font-weight: bold;
  color: $black;
  text-transform: uppercase;
  border-bottom: 1px #dcdcdd solid;
  .icon-arrow_calendar-left,
  .icon-arrow_calendar-right {
    width: 6px;
    height: 12px;
    fill: $silver-two;
    vertical-align: 4px;
  }
}
