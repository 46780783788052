.person {
	text-align: center;
	font-size: rem(18);
	&__photo {
		border-radius: 50%;
	}
	&__name {
		margin-top: 18px;
		font-weight: bold;
	}
	&__post {
	
	}
}