.pager {
	text-align: center;
	font-size: 0;
	margin: 45px 0;
	&__item {
		margin: 0 4px;
		color: $black;
		width: 32px;
		height: 32px;
		display: inline-block;
		line-height: 32px;
		text-align: center;
		font-size: rem(16);
		border-radius: 50%;
		&--active, &:hover {
			background: $pinkish-red-two;
			color: #FFF;
		}
	}
	&__delimiter {
		display: inline-block;
		margin: 0 4px;
		font-size: rem(16);
	}
}