.styled-link {
	border: solid 6px #edeef0;
	display: block;
	margin-bottom: 16px;
	font-size: rem(18);
	font-weight: bold;
	color: $black;
	min-height: 120px;
	line-height: rem(120);
	position: relative;
	&:hover {
		color: $black;
		background: #edeef0;
	}
}
.styled-link__align {
	margin-left: 36%;
	display: inline-block;
	line-height: normal;
	vertical-align: middle;
	&--no-icon {
		margin-left: 0;
		text-align: center;
		width: 100%;
		padding: 0 20px;
	}
}
.styled-link__small {
	font-size: rem(16);
	display: inline-block;
}
.styled-link__big {
	font-size: rem(24);
	line-height: rem(34);
	display: inline-block;
}
.styled-link__icon {
	fill: $charcoal-grey;
	position: absolute;
	top: 50%;
	left: 18%;
	transform: translate(-50%, -50%);
	fill: #2e2d2c;
}
.styled-link--opv {
	.styled-link__icon {
		left: 14%;
	}
	.styled-link__align {
		margin-left: 29%;
		padding-right: 10px;
	}
}
.styled-link__icon-calendar {
	width: 35px;
	height: 38px;
}
.styled-link__icon-for-incoming {
	width: 35px;
	height: 35px;
}
.styled-link__icon-opornii-vuz_obrazovanie {
	width: 47px;
	height: 44px;
}
.styled-link__icon-index_article {
	width: 31px;
	height: 40px;
}
.styled-link__icon-rocket {
	width: 30px;
	height: 52px;
}
.styled-link__icon-case {
	width: 45px;
	height: 40px;	
}
.styled-link__icon-rouble {
	width: 35px;
	height: 39px;
}
.styled-link__icon-piggybank {
	width: 66px;
	height: 45px;
}
.styled-link__icon-piggybank {
	width: 43px;
	height: 37px;
}
.styled-link__icon__innovation {
	width: 45px;
	height: 52px;
}
.styled-link__icon-napravlenia {
	width: 36px;
	height: 47px;
}
.styled-link__icon-opv1 {
	width: 47px;
	height: 47px;
}
.styled-link__icon-opv2 {
	width: 50px;
	height: 46px;
}
.styled-link__icon-opv3 {
	width: 47px;
	height: 47px;
}
.styled-link__icon-opv4 {
	width: 47px;
	height: 47px;
}
.styled-link__icon-opv5 {
	width: 47px;
	height: 47px;
}
.styled-link__icon-opv6 {
	width: 47px;
	height: 47px;
}
.styled-link__icon-opv7 {width: 47px;
	height: 47px;}
@media (max-width: 767px){
	.styled-link {
		padding-right: 10%;
	}
}