.incoming-title {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.incoming-title__icon-box {
  flex: 0 0 75px;
  @media (max-width: 767px) {
    display: none;
  }
}
.incoming-title__icon {
  width: 37px;
  height: 54px;
  fill:#000;
}
.incoming-title__text {
  font-size: rem(34);
  font-weight: 700;
  line-height: 38px;
  display: inline-block;
  margin: 0;
  @media (max-width: 767px) {
    font-size: rem(32);
    line-height: 30px;
  }
}

.incoming-title--rule {
  width: 48px;
  height: 48px;
}
.incoming-title--places {
  width: 45px;
  height: 46px;
}
.incoming-title--inv {
  width: 48px;
  height: 48px;
}
.incoming-title--contract {
  width: 48px;
  height: 48px;
}
.incoming-title--info {
  width: 46px;
  height: 48px;
}
.incoming-title--tbl {
  width: 37px;
  height: 48px;
}
.incoming-title--medal {
  width: 40px;
  height: 48px;
}
.incoming-title--attention {
  width: 46px;
  height: 46px;
}
.incoming-title--results {
  width: 48px;
  height: 33px;
}
.incoming-title--schedule{
  width: 48px;
  height: 36px;
}
.incoming-title--form {
  width: 47px;
  height: 48px;
}
.incoming-title--time {
  width: 48px;
  height: 48px;
}
.incoming-title__note {
  font-size: rem(16);
}

