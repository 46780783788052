.fill-questionnaire-form {
	margin-top: 48px;
	height: 168px;
	background-color: $bright-cyan;
	padding: 26px 34px 26px 32px;
	overflow: hidden;
	color: #FFF;
	font-weight: bold;
	h3 {
		margin: 0;
	}
}
.fill-questionnaire-form__left {
	float: left;
	width: 271px;
}
.fill-questionnaire-form__right {
	float: right;
	width: 243px;
	.styled-button {
		margin-bottom: 16px;
	}
}
@media (max-width: 767px) {
	.fill-questionnaire-form {
		height: auto;
	}
	.fill-questionnaire-form__left {
		width: auto;
		float: none;
		text-align: center;
	}
	.fill-questionnaire-form__right {
		float: none;
		margin: 0 auto;
	}
}