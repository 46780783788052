.file-dield {
  position: relative;
  overflow: hidden;
  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
  }
  label {
    position: relative;
    z-index: 3;
    cursor: pointer;
  }
}