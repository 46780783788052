.veterans {
	overflow: hidden;
	margin-top: 50px;
}
.veterans__item {
	text-align: center;
	margin-bottom: 52px;
	color: $black;
	&:hover {
		color: $pinkish-red;
	}
}
.veterans__photo {
	border-radius: 50%;
}
.veterans__name {
	display: block;
	font-weight: bold;
	line-height: rem(18);
	text-align: center;
	margin-top: 16px;
}

.veteran {
	width: 680px;
	box-sizing: content-box;
	padding: 90px;
}
.veteran__photo {
	width: 153px;
	margin-right: 24px;
	float: left;
	overflow: hidden;
	border-radius: 50%;
}
.veteran__info {
	overflow: hidden;
}
.veteran__name {
	font-size: rem(36);
	font-weight: 700;
	line-height: 35px;
}
.veteran__about {

}

@media (max-width: 1200px) {
	.veteran {
		width: auto;
	}
}
@media (max-width: 767px) {
	.veteran {
		padding: 40px;
	}
	.veteran__photo {
		float: none;
		margin: 0 auto 10px;
	}
	.veteran__name  {
		text-align: center;
		margin-bottom: 20px;
	}
}