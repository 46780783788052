.univer-careers__note {
	margin: 46px 0 23px 0;
}
.univer-careers__item {
	padding: 45px 0;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
}
.univer-careers__date {
	color: $cement;
	margin-bottom: 15px;
}
.univer-careers__post {
	padding-bottom: 25px;
	margin-bottom: 25px;
	position: relative;
	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 43px;
		height: 3px;
		background-color: #ed1746;
	}
}
.univer-careers__pulpit {
	font-size: rem(24);
	font-weight: bold;
	line-height: 1.17;
}
.univer-careers__post-name {
	margin-top: 5px;
}
.univer-careers__place {
	margin-bottom: 24px;
	b {
		display: block;
	}
}
.univer-careers__requirements {
	b {
		display: block;
	}
}