.lang-selector {
	float: right;
	color: $black;
	font-size: rem(15);
	margin: 17px 0 0 0;
}
.lang-selector__link {
	color: $black;
	letter-spacing: 0.6px;
	
		&:hover {
			color: $pinkish-red-two;
		}
	
}
.lang-selector__link--active {
	color: $pinkish-red-two;
}

@media (max-width: 1200px) {
	.lang-selector {
		margin-top: 18px;
	}
} 