input[type="text"], input[type="password"], input[type="number"] {
	width: 100%;
	height: 48px;
	box-shadow: 0 5px 8px rgba(0, 36, 71, 0.12);
	background-color: #ffffff;
	padding: 0 18px;
	outline: 0;
	appearance: none;
	color: $black;
	font-size: rem(16);
	font-weight: 400;
	border: 1px #FFF solid;
	&:focus {
		border-color: $bright-cyan;
	}
	&.careers__serach-filed {
		box-shadow: none;
		border: solid 1px $silver;
		&:focus {
			box-shadow: 0 5px 8px rgba(0, 36, 71, 0.12);
		}
	}
}
input[type="text"]::-webkit-input-placeholder {font-size: rem(18); color: $cool-grey;}
input[type="text"]::-moz-placeholder          {font-size: rem(18); color: $cool-grey;}/* Firefox 19+ */
input[type="text"]:-moz-placeholder           {font-size: rem(18); color: $cool-grey;}/* Firefox 18- */
input[type="text"]:-ms-input-placeholder      {font-size: rem(18); color: $cool-grey;}

input[type="number"] {
	width: 80px;
	padding: 0 10px;
	font-size: rem(19);
	text-align: center;
}
.right-text {
	text-align: right;
}