.sidebar-menu {
	border: solid 1px #ededed;
	border-top: solid 3px $pinkish-red-two;
	border-bottom: 0;
	margin-bottom: 25px;
	a {
		color: $black;
	}
	.sidebar-menu__footer-link {
		margin-left: 25px;
		font-size: rem(15);
		font-weight: bold;
		letter-spacing: 0.6px;
		color: $light-navy;
		text-transform: uppercase;
	}
}
.sidebar-menu__footer {
	border-bottom: solid 1px #ededed;
	display: block;
	min-height: 60px;
	line-height: 60px;
	position: relative;
}
.sidebar-menu__item {
	border-bottom: solid 1px #ededed;
	display: block;
	min-height: 60px;
	line-height: 60px;
	position: relative;
	&:hover, &--active {
		background-color: $pale-grey;
	}
}
.sidebar-menu__item--gray {
	border-color: #dcdddf;
	background-color: $pale-grey;
	&:hover, &-active {
		background-color: $silver;
	}
}
.sidebar-menu__title {
	border-bottom: solid 1px #ededed;
	display: block;
	min-height: 60px;
	line-height: 60px;
	font-weight: bold;
}
.sidebar-menu__align {
	display: inline-block;
	line-height: normal;
	vertical-align: middle;
	padding: 20px 57px 20px 25px; 
}
.sidebar-menu__icon {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
}
.sidebar-menu__icon-icon-link {
	width: 18px;
	height: 18px;
	right: 24px;
	fill: #bacde1;
}
.sidebar-menu__icon-icon-download {
	width: 13px;
	height: 16px;
	fill: #bacde1;
	right: 24px;
}
.mobile-sidebar {
	padding: 0 12px 5px 12px;
	position: relative;
	z-index: 2;
	overflow: hidden;
	&--gray {
		background: #f5f6f8;
	}
	&--inheader {
		padding: 24px 12px 5px 12px;
	}
	&--inside {
		margin-bottom: 20px;
		.sidebar-menu-caption {
			border-top: 3px solid #ec1646;
		}
	}
}
.sidebar-menu-caption {
	height: 48px;
	line-height: 48px;
	background-color: white;
	box-shadow: 0 3px 2px rgba(0, 36, 71, 0.05);
	padding: 0 18px;
	font-size: 0;
	&--open {
		background: #e6e7e9;
		box-shadow: none;
	}
}
.sidebar-menu-caption__icon-menu {
	width: 16px;
	height: 12px;
	fill: #b4b5b6;
	margin-right: 20px;
	vertical-align: middle;
}
.sidebar-menu-caption__current {
	font-size: rem(16);
	font-weight: 400;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: calc(100% - 45px);
	display: inline-block;
	line-height: rem(18);
	vertical-align: middle;
}
@media(max-width: 1280px) {
	.mobile-sidebar {
		.sidebar-menu {
			display: none;
			background: #ffffff;
			border-color: #e7e7e7;
			border-top: solid 1px #e7e7e7;
			.sidebar-menu__item {
				background: #ffffff;
			}
			& + .sidebar-menu {
				.sidebar-menu__item {
					background: #f5f6f8;
				}	
			}
		}
	}
}
