.schedule {
	width: 100%;
	border-collapse: collapse;
	table-layout:fixed;
	background: #ffffff;
	margin-top: -1px;
	th {
		border: 1px #ededed solid;
		padding: 10px 0;
	}
	td {
		padding: 10px 0;
		text-align: center;
		border: 1px #ededed solid;
		font-size: rem(14);
		height: 76px;
		line-height: 16px;
		position: relative;
	}
	.schedule__date-col {
		width: 53px;
	}
	.schedule__time-col {
		width: 78px;
	}
	.schedule__start-of-day {
		td {
			border-top-width: 2px;
		}
		&:first-child {
			td {
				border-top-width: 1px;
			}	
		}
	}
}
.schedule__dsr {
	line-height: rem(50);
}
.schedule__vertical-date {
	font-size: rem(16);
	line-height: rem(20);
	margin-left: -38px;
	transform: rotate(-90deg);
	width: 130px;
	b {
		text-transform: uppercase;
	}
	
}
.schedule__time {
	font-size: rem(16);
}


.schedule-faculty-menu {
	height: 48px;
	display: flex;
	background-color: #4d4d4f;
}
.schedule-faculty-menu__link {
	text-align: center;
	flex: 1;
	height: 48px;
	line-height: 48px;
	color: #ffffff;
	font-size: 15px;
	font-weight: 700;
	background-color: #4d4d4f;
	&:hover, &--active {
		background-color: #44c8f5;
		color: #ffffff;
	}
}
.schedule-faculty-and-course {
	overflow: hidden;
}
.schedule-current-faculty {
	float: left;
	width: 490px;
	height: 48px;
	line-height: 48px;
	background-color: #e6e7e9;
	font-weight: 700;
	text-align: center;
}
.schedule-courses {
	overflow: hidden;
	display: flex;
}
.schedule-courses__link {
	flex: 1;
	text-align: center;
	background-color: #004182;
	height: 48px;
	line-height: 48px;
	color: #ffffff;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 700;
	&:hover, &--active {
		background-color: #44c8f5;
		color: #ffffff;
	}
}
.schedule-sliding-section {
	background: #f5f6f8;	
	z-index: 50;
	h1 {
		float: left;
	}
	&--fixed {
		position: fixed;
		left: 50%;
		top: -20px;
		margin-left: -522.5px;
		width: 1045px;
	}
	.schedule{
		margin-top: 0;
	}
}
.schedule-sliding-spacer {
	height: 262px;
	display: none;
}

.schedule__day {
	// position: relative;
	&:hover {
		.schedule__day-full {
			display: block;
		}
	}
}
.schedule__day-full {
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 183px;
	min-height: 100%;
	background-color: #f5f6f8;
	box-shadow: 0 2px 30px rgba(0, 0, 0, 0.24);
	text-align-last: left;
	padding: 15px;
	z-index: 20;
	line-height: 19px;
}
.schedule-selects {
  margin-bottom: 15px;
}
td[class^="group-col-"] {
	display: table-cell;
}
@media (max-width: 1200px) {
  td[class^="group-col-"] {
    display: none;
  }
  td.group-col-1 {
    display: table-cell;
  }
  .schedule__group-col {
    width: calc(100vw - 180px);
  }  
  // .schedule {
  //   display: none;
  // }
  .schedule-sliding-section {
    h1 {
      
      font-size: 28px;
    }
  }
}
@media (max-width: 1200px) {
	.schedule-page {
		overflow: hidden;
	}
	.schedule-sliding-section h1 {
		float: none;
	}
	.week-selector {
		width: 767px;
		margin-top: 0;
		margin-bottom: 15px;
		float: left;
	}
	.week-selector__dropdown {
		width: 290px;
	}
	.week-selector__item {
		overflow: hidden;
		white-space: nowrap;
	}
	.week-selector__current {
		width: 290px;
		box-shadow: none;
	}
}