.colored-menu {
	float: left;
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 0;
	li {
		display: inline-block;
		background: #ffffff;
	}
}
.colored-menu__item {
	display: block;
	width: 190px;
	height: 60px;
	line-height: 60px;
	font-size: rem(15);
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.6px;
	color: #FFF;
	text-align: center;
	&:hover {
		color: #ffffff;
		opacity: .8;
	}
}
.colored-menu--blue {
	background-color: $bright-cyan;
}
.colored-menu--pink {
	background-color: $pinkish-red-two;
}
.colored-menu--lightgray {
	background-color: $charcoal-grey;
	width: 180px;
}
.colored-menu--darkgray {
	background-color: $black;
	width: 120px;
}
@media(max-width: 1200px) {
	.colored-menu__item {
		font-size: rem(14);
	}
	.colored-menu__item, .colored-menu--lightgray {
		width: 143px;
	}
}