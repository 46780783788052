.science-and-innovation {
	background-color: $pale-grey;
	padding-bottom: 70px;
}
.science-and-innovation__item {
	float: left;
	width: 267px;
	margin-bottom: 37px;
}
.science-and-innovation__item--fill-width {
	width: 100%;
}
.science-and-innovation__icon {
	float: left;
	svg {
		fill: $black;
	}
}
.science-and-innovation__item-text {
	font-size: rem(20);
	overflow: hidden;
	color: $black;
}
.science-and-innovation__item-num {
	font-size: rem(48);
	font-weight: bold;
	line-height: rem(40);
	margin-bottom: 5px;
	span {
		font-size: rem(80);
		display: inline-block;
		line-height: rem(70);
	}
}
.icon-index_article {
	width: 60px;
	height: 82px;
	margin-right: 30px;
}
.icon-index_hirsh {
	width: 57px;
	height: 43px;
	margin-right: 35px;
}
.icon-index_innovation {
	width: 71px;
	height: 114px;
	margin-right: 24px;
}
.icon-fan_innovation {
	width: 71px;
	height: 114px;
	margin-right: 24px;
}
.icon-meeting {
	width: 70px;
	height: 81px;
	margin-right: 24px;
}
.icon-diplom {
	width: 70px;
	height: 63px;
	margin-right: 24px;
}
.icon-bak {
	width: 69px;
	height: 96px;
	margin-right: 24px;
}
.icon-index_vak {
	width: 69px;
	height: 96px;
	margin-right: 31px;
}
.icon-index_dissertation {
	width: 100px;
	height: 75px;
	margin-right: 14px;
	margin-left: -10px;
}
.icon-index_patent {
	width: 91px;
	height: 77px;
	margin-right: 20px;
}
.icon-index_rouble {
	width: 63px;
	height: 69px;
	margin-right: 22px;
}
.science-and-innovation__schema {
	width: 535px;
	height: 752px;
	// background: url(../img/sai2.png);
	background: url(../img/sai3.png) no-repeat;
	margin-top: 48px;
	position: relative;
}
.science-and-innovation__schema-item {
	width: 190px;
	height: 190px;
	line-height: 190px;
	background-color: $darkish-blue;
	text-align: center;
	font-size: rem(15);
	font-weight: bold;
	border-radius: 50%;
	color: #FFF;
	text-transform: uppercase;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	span {
		display: inline-block;
		line-height: normal;
		vertical-align: middle;
	}
	&--small {
		background: #44c8f5;
		width: 136px;
		height: 136px;
		line-height: 136px;
	}
}
.science-and-innovation__schema--p1 {
	top: 0;
}
.science-and-innovation__schema--p2 {
	bottom: 0;
	width: 200px;
	height: 200px;
	line-height: 200px;
}
.science-and-innovation__schema--p3 {
	left: 0;
	top: 250px;
	transform: translate(0, 0);
}
.science-and-innovation__schema--p4 {
	top: 250px;
	margin-left: 172px;
}
.science-and-innovation__schema--p5 {
	top: 105px;
	left: 21px;
	transform: translate(0, 0);
}
.science-and-innovation__schema--p6 {
	top: 105px;
	margin-left: 177px;
}
.science-and-innovation__schema--p7 {
	left: 21px;
	top: 455px;
	transform: translate(0, 0);
}
.science-and-innovation__schema--p8 {
	top: 408px;
}
.science-and-innovation__schema--p9 {
	margin-left: 177px;
	top: 454px;
}
.science-and-innovation__schema-title {
	font-size: 13px;
	font-weight: 700;
	line-height: 20px;
	text-transform: uppercase;
	text-align: center;
	position: absolute;
	left: 203px;
	top: 331px;
}
@media (max-width: 1200px) {
	.science-and-innovation__schema {
		margin: 139px auto 147px;
	}
}
@media (max-width: 767px) {
	.science-and-innovation__schema {
		display: none;
	}
	.science-and-innovation__item-num span {
		font-size: rem(46);
	}
	.icon-index_rouble {
		width: 40px;
		height: 44px;
	}
}