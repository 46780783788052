.rectorat {
	max-width: 1046px;
	width: 100%;
	margin: 70px auto 0;
}

.rectorat-grid {
	display: flex;
	flex-wrap: wrap;
	&__col {
		width: 50%;
		border-top: 1px #e5e5e5 solid;
		border-right: 1px #e5e5e5 solid;
		&:nth-child(even) {
			border-right: 0;
		}
		&:nth-child(1), &:nth-child(2) {
			border-top: 0;
		}
	}
}

.rectorat-person {
	width: 460px;
	padding: 50px 0;
	&__photo {
		float: left;
		margin-right: 25px;
		img {
			border-radius: 50%;
		}
	}
	&__info {
		overflow: hidden;
	}
	&__post {
		font-size: rem(18);
	}
	&__name {
		font-size: rem(18);
		font-weight: 700;
		margin: 11px 0 15px 0;
		padding-bottom: 17px;
		position: relative;
		&:before {
			content: "";
			width: 45px;
			height: 3px;
			background: #ed1746;
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}
	&__text {
		margin-top: 10px;
	}
	&--odd {
		float: left;
	}
	&--even {
		float: right;
	}
	.contacts-item {
		margin-top: 0;
	}
	p {
		margin: 5px 0;
	}
}
.rectorat-person--wa {
	width: auto;
	padding: 50px 20px;
}
@media (max-width:1200px) {
	.rectorat {
		margin: 10px auto 0;
	}
	.rectorat-grid {
		display: block;
	}
	.rectorat-person {
		float: none!important;
	}
	.rectorat-person {
		width: auto;
		margin: 0 50px;
		border-bottom: 1px #e5e5e5 solid;
	}
}
@media (max-width:767px) {
	.rectorat-person__photo {
		float: none;
		margin: 0 0 15px 0;
		text-align: center;
	}
	.rectorat-person {
		margin: 0 12px;
	}
}