.main-slider {
	width: 100%;
	// max-height: 571px;
	overflow: hidden;
	max-width: 1920px;
	margin: 0 auto;
	.slick-dots {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	.main-slider__arrow {
		padding: 0;
		width: 48px;
		height: 48px;
		border: 0;
		background: #2e2d2c;
		position: absolute;
		bottom: 0;
		z-index: 10;
		outline: 0;
		svg {
			width: 9px;
			height: 14px;
			fill: #FFF;
		}
		&:hover {
			background: #4d4d4f;
		}
	}
	.main-slider__arrow--prev {
		right: 48px;
		svg {
			transform: rotate(90deg);
		}
		@media screen and (max-width: 1200px) {
			right: 0;
			bottom: 48px;
		}
	}
	.main-slider__arrow--next {
		right: 0;
		svg {
			transform: rotate(90deg);
		}
	}
	.slick-slide {
		font-size: 0;
		line-height: 0;
	}
}
.main-slider__slide-item {
	font-size: 0;
	line-height: 0;
	position: relative;
	display: none;
	&:first-child {
		display: block;
	}
}
.main-slider__slide-container {
	float: left;
	width: 72%;
	img {
		display: block;
		max-width: 100%;
		height: auto;
	}
}
.main-slider__preview-slider {
	float: right;
	width: 28%;
	position: relative;
}
.main-slider__preview-item {
	height: 136px;
	line-height: 136px;
	width: 100%;
	font-size: rem(18);
	font-weight: bold;
	color: #FFF;
	background-color: $darkish-blue;
	border: 0;
	border-top: 1px $marine-blue solid;
	padding: 0 25px;
	text-align: left;
	outline: 0;
	span {
		display: inline-block;
		line-height: normal;
		vertical-align: middle;
	}
	&:hover, &.main-slider__preview-item--active {
		background: $dusk-blue;
		cursor: pointer;
	}
}
.slick-dots .slick-active .main-slider__preview-item {
	background: $dusk-blue;
	cursor: pointer;
}
.slick-dots li:first-child .main-slider__preview-item {
	border: 0;
}
.main-slider__text {
	position: absolute;
	z-index: 2;
	//background:rgba(#2e2d2c, .9);
	background: rgba($color: #004282, $alpha: .5);
	color: #ffffff;
	text-decoration: none;
	padding: 15px;
	font-size: rem(35);
	left: 50px;
	bottom: 80px;
	margin-right: 50px;
	line-height: normal;
	&:hover {
		color: #ffffff;
	}
}
.main-slider__text-container {
	position: absolute;
	z-index: 2;
	left: 50px;
	bottom: 40px;
	font-size: rem(35);
	line-height: normal;
	padding-right: 80px;
	&:hover {
		.text-bg-level1,.text-bg-level2,.text-bg-level3 {
			background: rgb(38, 94, 149);
		}		
	}
}
.text-bg-level1,.text-bg-level2,.text-bg-level3 {
	padding: 3px 0;
	position: relative;
	background: #004282;
	color: white;
}
.text-bg-level1 {
	left: -20px;
}
.text-bg-level2 {
	left: 20px;
}
.text-bg-level3 {
	left: -10px;
	color: white;
	&:hover {
		color: white;
	}
}
@media (max-width:1200px) {
	.main-slider__slide-container {
		width: 100%;
	}
	.main-slider__preview-slider {
		display: flex;
		height: auto!important;
		width: 100%;
	}
	.main-slider__preview-item {
		border: 0;
		border-left: 1px $marine-blue solid;
		
	}
	.main-slider__text {
		left: 40px;
		bottom: 40px;
		margin-right: 80px;
	}
}
@media (max-width: 767px) {
	.main-slider__preview-slider {
		display: block;
	}
	.main-slider__preview-item {
		border: 0;
		border-top: 1px $marine-blue solid;	
		height: auto!important;
		line-height: normal!important;	
		padding: 15px 25px;
	}
	.main-slider__text {
		font-size: rem(18);
		bottom: 20px;
		left: 20px;
	}
	.main-slider__text-container {
		font-size: rem(18);
		bottom: 20px;
		
	}
}