.mission {
	margin-bottom: 70px;
	overflow: hidden;
	.container {
		position: relative;
		z-index: 4;
	}
	&__video {
		position: absolute;
		height: 240px;
		background-color: $darkish-blue;
		left: 50%;
		top: -185px;
		width: 100%;
		transform: translate(-166px, 0);
		z-index: 5;
	}
	&__video-text {
		width: 319px;
		color: #FFF;
		margin: 40px 58px 0 68px;
		float: left;
	}
	&__video-poster {
		position: relative;
		display: block;
		float: left;
		&:after {
			position: absolute;
			content: "";
			width: 87px;
			height: 87px;
			border-radius: 50%;
			background: #ed1746;
			box-shadow: 0 2px 5px 0 rgba(61, 53, 37, 0.6);
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
		}
		&:before {
			position: absolute;
			content: "";
			z-index: 3;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 15.5px 0 15.5px 25px;
			border-color: transparent transparent transparent #ffffff;
			left: 50%;
			top: 50%;
			margin: -16px 0 0 -8px;
		}
		&:hover {
			&:after {
				background: $pinky-red;
			}
		}
	}
	&__top {
		min-height: 504px;
		background: $bright-cyan;
		margin-bottom: 63px;
		padding-top: 78px;
		color: #FFF;
		overflow: hidden;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			top: 30px;
			left: 50%;
			transform: translate(227px, 0);
			background: url(../img/mission-bg.png);
			width: 474px;
			height: 474px;
			z-index: 2;
		}
	}
	&__bottom {
		padding-top: 100px;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			background: url(../img/mission-block-bg.png) no-repeat;
			left: 50%;
			top: 0;
			width: 330px;
			height: 463px;
			transform: translate(-575px, 0);
		}
	}
	&__num {
		font-size: rem(202);
		line-height: rem(155);
		font-weight: bold;
	}
	&__num-text {
		font-size: rem(18);
		font-weight: bold;
		margin-bottom: 40px;
	}
	&__title {
		margin: 0 0 30px 63px;
	}
	&__list {
		list-style: none;
		margin: 0;
		padding: 0 0 0 30px;
		li {
			padding-left: 36px;
			position: relative;
			margin-bottom: 27px;
			font-size: rem(18);
			font-weight: bold;
			&:before {
				content: "";
				position: absolute;
				width: 24px;
				height: 24px;
				background: url(../img/li-marker.svg) no-repeat;
				left: 0;
				top: 0;
			}
		}
	}
}

@media (max-width: 1200px) {
	.mission__top {
		margin-bottom: 0;
	}
	.mission__video {
		position: static;
		transform: translate(0,0);
		margin-bottom: 35px;
	}
	.mission__bottom {
		padding-top: 0;
	}
	.mission__video-text {
		width: 50%;
		box-sizing: border-box;
		margin: 0;
		padding: 32px 28px 0 38px;
	}
	.mission__video-poster {
		float: right;
	}
	.mission__bottom:before {
		width: 249px;
		height: 350px;
		left: 35px;
		top: 280px;
		background-size: cover;
		transform: translate(0, 0);
	}
}
@media (max-width: 767px) {
	.mission__title {
		margin-left: 0;
		margin-top: 30px;
	}
	.mission__list {
		padding-left: 0;
	}
	.mission__num-text + .styled-button {
		margin: 0 auto;
		display: block;
	}
	.mission__video {
		height: auto;
	}
	.mission__video-poster {
		float: none;
	}
	.mission__video-text {
		width: 100%;
		float: none;
		padding: 20px;
	}
	.mission__bottom:before {
		display: none;
	}
}