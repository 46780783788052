//@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i&subset=cyrillic');

$bluish: #2285bb;
$light-blue-grey: #b7cbe0;
$navy: #002045;
$marine-blue: #002e5b;
$bright-cyan: #44c8f5;
$purple-brown: #652932;
$pinkish-red-two: #ec1646;
$pinkish-red: #ed1847;
$silver-two: #c9c9ca;
$water-blue: #1aaadb;
$silver-three: #dedfe0;
$dusk-blue: #265e95;
$pale-grey: #f5f6f8;
$charcoal-grey: #4d4d4f;
$pinky-red: #ff174b;
$light-navy: #0f5499;
$black: #2e2d2c;
$silver: #e6e7e9;
$darkish-blue: #004282;
$slate-grey: #6e6e71;
$cool-grey: #b4b5b6;
$cement: #989897;

$baseFontSize: 16;

@function rem($pixels, $context: $baseFontSize) {
  // @if (unitless($pixels)) {
  //   $pixels: $pixels * 1px;
  // }

  // @if (unitless($context)) {
  //   $context: $context * 1px;
  // }

	// @return $pixels / $context * 1rem;
	@return ($pixels - 2) * 1px;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url(../fonts/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url(../fonts/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url(../fonts/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url(../fonts/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url(../fonts/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url(../fonts/JTUPjIg1_i6t8kCHKm459WxZcgvz8fZwjimrq1Q_.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url(../fonts/JTUPjIg1_i6t8kCHKm459WxZcgvz-PZwjimrq1Q_.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url(../fonts/JTUPjIg1_i6t8kCHKm459WxZcgvz8_Zwjimrq1Q_.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url(../fonts/JTUPjIg1_i6t8kCHKm459WxZcgvz8vZwjimrq1Q_.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url(../fonts/JTUPjIg1_i6t8kCHKm459WxZcgvz_PZwjimrqw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(../fonts/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(../fonts/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(../fonts/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(../fonts/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(../fonts/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(../fonts/JTURjIg1_i6t8kCHKm45_dJE3gTD_vx3rCubqg.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(../fonts/JTURjIg1_i6t8kCHKm45_dJE3g3D_vx3rCubqg.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(../fonts/JTURjIg1_i6t8kCHKm45_dJE3gbD_vx3rCubqg.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(../fonts/JTURjIg1_i6t8kCHKm45_dJE3gfD_vx3rCubqg.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(../fonts/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// @font-face {
//   font-family: 'ProximaNova';
//   src: url('../fonts/proxima_nova_regular-webfont.woff2') format('woff2'),
//        url('../fonts/proxima_nova_regular-webfont.woff') format('woff');
//   font-weight: 400;
//   font-style: normal;

// }
// @font-face {
//   font-family: 'ProximaNova';
//   src: url('../fonts/proxima_nova_regular_italic-webfont.woff2') format('woff2'),
//        url('../fonts/proxima_nova_regular_italic-webfont.woff') format('woff');
//   font-weight: 400;
//   font-style: italic;
// }
// @font-face {
//   font-family: 'ProximaNova';
//   src: url('../fonts/proxima_nova_bold-webfont.woff2') format('woff2'),
//        url('../fonts/proxima_nova_bold-webfont.woff') format('woff');
//   font-weight: 700;
//   font-style: normal;

// }
// @font-face {
//   font-family: 'ProximaNova';
//   src: url('../fonts/proxima_nova_bold_italic-webfont.woff2') format('woff2'),
//        url('../fonts/roxima_nova_bold_italic-webfont.woff') format('woff');
//   font-weight: 700;
//   font-style: italic;
// }



html {
	font-size: 14px;
}
body {
	color: $black;
	// font-family: "ProximaNova";
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	line-height: 24px;
	background: #FFF;
	font-size: rem(16);
	-webkit-font-smoothing:antialiased;
	&.gray-bg {
		background: #f5f6f8;
	}
}

.login-bt {
	padding: 0;
	border-radius: 0;
	border: 0;
	width: 26px;
	height: 23px;
	float: right;
	margin: 19px 0 0 0;
	background: none;
	outline: none;
	svg {
		width: 26px;
		height: 23px;
		fill: $slate-grey;
	}
	&:hover, &:focus {
		svg {
			fill: $cool-grey;
		}
	}
}

.scale-font {
	float: right;
	border: 0;
	background: none;
	padding: 0;
	width: 42px;
	height: 18px;
	margin: 21px 23px 0 0;
	svg {
		width: 42px;
		height: 18px;
		fill: $slate-grey;
	}
	&:hover {
		svg {
			fill: $cool-grey;
		}
	}
}
.new-journal {
	font-size: 0;
	line-height: 0;
	position: relative;
	display: block;
	margin-bottom: 24px;
	img {
		width: 100%;
		height: auto;
		filter: grayscale(100%);
	}
	span {
		position: absolute;
		width: 149px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		color: #FFF;
		background: $pinky-red;
		top: 23px;
		left: 0;
		font-size: rem(15);
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 0.6px;
		z-index: 3;
	}
	&:hover {
		img {
			filter: grayscale(0);
		}
	}
}
.partners-list {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 50px 0 0 0;
	&__item {
		filter:url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /*     Firefox 10+, Firefox on Android */
		filter:gray; /* IE6-9 */
		filter: grayscale(100%);
		opacity: .5;
		
		margin: 0 20px 40px;
		&:hover {
			opacity: 1;
			filter: none;
		}
	}
}
.contacts-item {
	margin-top: 10px;
	&--bold {
		font-weight: bold;
	}
	.icon-telefone {
		width: 16px;
		height: 17px;
		fill: $pinkish-red;
	}
	.icon-mail {
		width: 14px;
		height: 9px;
		fill: $pinkish-red;
	}
	.icon {
		margin-right: 7px;
		vertical-align: middle;
	}
	.icon-location {
		width: 12px;
	height: 15px;
	}
	.icon-fax {
		width: 12px;
		height: 12px;
	}
}
.t0 {
	margin-top: 0;
}



.history {
	position: relative;
}
.history__item {
	margin-bottom: 35px;
}
.history__year {
	font-size: rem(32);
	font-weight: 700;
	width: 65px;
	float: left;
	margin-right: 24px;
	margin-top: 5px;
	text-align: center;
	@media (max-width:767px) {
		float: none;
		width: auto;
		margin-right: 0;
		margin-bottom: 10px;
		text-align: left;
	}
}
.history__text {
	overflow: hidden;
}
.history__photo {
	margin-top: 40px;
	img {
		display: block;
		width: 100%;
		height: auto;
	}
}
.history__photo-text {
	font-style: italic;
	margin-top: 7px;
	padding-bottom: 10px;
	border-bottom: 1px #e5e5e5 solid;
	&--nob {
		border-bottom: 0;
		padding-bottom: 0;
	}
}
.history__delimiter {
	margin: 15px 0 50px 0;
	border-top-color: #e5e5e5;
}

.history-person {
	text-align: center;
	width: 200px;
	margin: 0 auto 38px;
	&--big {
		width: 215px;
	}
	&--voskoboinikov {
		margin-bottom: 434px;
	}
	&--kozlow {
		margin-bottom: 434px;
	}
	&--samarin {
		margin-bottom: 511px;
	}
	@media (max-width: 767px) {
		& {
			margin: 0 auto 20px;
		}
		&--voskoboinikov,&--kozlow,&--samarin {
			margin: 0 auto 20px;
		}
	}
}
.history-person__photo {
	margin-bottom: 10px;
	img {
		border-radius: 50%;
	}
}
.history-person__name {
	font-weight: bold;
	margin-bottom: 10px;
	font-size: rem(18);
	line-height: rem(20);
}
.history-person__info {
	font-style: italic;
	line-height: rem(18);
}
.history-person__year {
	font-weight: bold;
}


.history-styled-link {
	display: block;
	position: relative;
	color: #FFF;
	&--has-margin {
		margin-bottom: 35px;
	}
	img {
		width: 100%;
		height: auto;
		display: block;
		position: relative;
		z-index: 1;
	}
		&:hover {
			color: #FFF;
		}
}
.history-styled-link__text {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	background: rgba(0, 66, 130, .5);
	z-index: 2;
		&:hover {
			background:rgba(68,200,245,.8);
		}
}
.history-styled-link__align {
	width: 100%;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(0, -50%);
}
.history-styled-link__title {
	font-size: rem(24);
}
.history-styled-link__text__descr {
	font-style: italic;
	display: block;
	margin: 10px 0 25px 0;
}
.history-styled-link__icon-eye {
	width: 48px;
	height: 25px;
	fill: #FFF;
}
.years-menu {
	position: absolute;
	width: 165px;
	top: 0;
	right: 0;
	padding-left: 40px;
	&:before {
		content: "";
		position: absolute;
		left: 5px;
		top: 20px;
		height: calc(100% - 40px);
		width: 1px;
		background-color: $silver-two;
	}
	&--fixed {
		position: fixed;
		top: 20px;
		right: 50%;
		transform: translate(523px, 0);
	}
}
.years-menu__item {
	display: block;
	height: 50px;
	line-height: 50px;
	font-size: rem(18);
	color: $silver-two;
	position: relative;
	&:after {
		position: absolute;
		content: "";
		width: 11px;
		height: 11px;
		border-radius: 50%;
		background-color: $silver-two;
		left: -40px;
		top: 50%;
		transform: translate(0, -50%);
	}
	&--active, &:hover {
		font-size: rem(22);
		color: $black;
		font-weight: 7;
		&:after {
			background-color: $pinkish-red-two;
		}
		&:before {
			content: "";
			position: absolute;
			width: 25px;
			height: 25px;
			border: 1px $silver-two solid;
			left: -47px;
			top: 50%;
			transform: translate(0, -50%);
			background:#FFF;
			border-radius: 50%;
		}
	}
}

.structure__tbl {
	border-collapse: collapse;
	width: 100%;
	td {
		padding: 17px 0;
		border-bottom: 1px #e1e2e3 solid;
		vertical-align: top;
	}
	tr:last-child {
		td {
			border-bottom: 0;
		}
	}
}
.faculty-link {
	&:hover {
		color: #ec1646;
	}
}

.fancy--custom {
	background: $pale-grey;
	.fancybox-close-small {
		width: 65px;
		height: 65px;
		background: $charcoal-grey url(../img/close.svg) center no-repeat;
		background-size: 16px 16px;
		&:after {
			display: none;
		}
		&:hover {
			background-color: $black;
		}
	}
}

.question-form {
	padding: 57px 90px;
	width: 332px;
	box-sizing: content-box;
}

.form__title {
	font-size: rem(36);
	font-weight: 700;
	margin-bottom: 46px;
}
.form__note {
	margin-bottom: 30px;
}
.fancy-message {
	padding: 90px 50px;
	font-size: rem(36);
	font-weight: 700;
}
.devepoler {
	position: absolute;
	right: 0;
    bottom: 0;
}

.interview-list {
	li {
		margin-bottom: 15px;
	}
}
.gray-block {
	background: #f5f6f8;
	padding-bottom: 40px;
	margin-top: 70px;
	margin-bottom: 70px;
}
.outstanding-students {
	
	h2 {
		margin-bottom: 45px;
	}
	hr {
		border-top-color: #dcdddf;
		margin-top: 50px;
		margin-bottom: 0;
	}
}
.outstanding-students__top-item {

}
.outstanding-students__photo {
	float: left;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	margin-right: 24px;
	@media (max-width: 1200px) {
		float: none;
		display: block;
	}
	@media (max-width: 767px) {
		float: none;
		display: block;
		margin: 0 auto;
	}
}
.outstanding-students__top-info {
	overflow: hidden;
	padding-top: 15px;
}
.outstanding-students__name {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 10px;
}
.outstanding-students__top-item {
	.outstanding-students__name {
		@media (max-width: 767px) {
			text-align: center;
		}
	}
}
.outstanding-students__achievements {

}
.outstanding-students__item {
	margin-bottom: 35px;
}

.facts-rating {
	h4 {
		margin-top: 0;
	}
}
.facts-rating__item {
	margin-bottom: 17px;
}
.facts-rating__name {
	color: #0f5499;
}
.facts-rating__palce {
	b {
		display: block;
		font-weight: 700;
	}
}

.facts-number-item {
	margin-bottom: 35px;
	overflow: hidden;
	&--left {
		float: left;
		margin-right: 25px;
	}
}
.facts-number-item__svg {
	fill: #4c4c4c;
}
.facts-number-item__icon {
	width: 50px;
	text-align: right;
	margin-right: 25px;
	float: left;
}
.facts-number-item__num {
	font-size: 46px;
	font-weight: 700;
	line-height: 47px;
	&--large {
		font-size: 52px;
	}
	&--inline {
		vertical-align: middle;
		display: inline-block;
		margin-right: 10px;
	}
}
.facts-number-item__description {
	font-size: 18px;
	line-height: 22px;
	&--small {
		font-size: 16px;
		line-height: 20px;
	}
	&--inline {
		vertical-align: middle;
		display: inline-block;
		margin-right: 10px;
	}
}
.facts-number-item__info {
	overflow: hidden;
	&--inline {
		line-height: 47px;
	}
}

.facts-number-item__icon-internet {
	width: 42px;
	height: 42px;
}
.facts-number-item__icon-obrazovanie {
	width: 50px;
	height: 47px;
}
.facts-number-item__icon-book {
	width: 42px;
	height: 39px;
}
.facts-number-item__icon-favorites {
	width: 40px;
	height: 39px;
}
.facts-number-item__icon-fan_innovation {
	width: 32px;
	height: 52px;
}
.facts-number-item__icon-diplom {
	width: 41px;
	height: 35px;
}
.facts-number-item__icon-meeting {
	width: 43px;
	height: 49px;
}
.facts-number-item__icon-bak {
	width: 33px;
	height: 44px;
}
.facts-number-item__icon-books {
	width: 44px;
	height: 34px;
}
.facts-number-item__icon-atom {
	width: 42px;
	height: 38px;
}
.facts-number-item__icon-monument {
	width: 42px;
	height: 41px;
}
.facts-number-item__icon-tent {
	width: 50px;
	height: 33px;
}
.facts-otange-title {
	text-transform: uppercase;
	color: #ff6700;
	font-size: 19px;
	margin-bottom: 15px;
}

.fan-campus {
	// background: #f5f6f8;
	padding-bottom: 84px;
	margin-top: 35px;
}

.content-slider {
	.slick-slide {
		font-size: 0;
		line-height: 0;
	}
}
.content-slider {
	&--news {
		margin-bottom: 20px;
	}
	.content-slider__img-box {
		height: 501px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #f5f6f8;
		img {
			display: inline-block;
			width: auto;
			max-height: 100%;
			max-width: 100%;
		}
	}
}
.content-slider__text {
	font-size: 16px;
	line-height: 22px;
	padding-left: 20px;
}
.content-slider__name {
	font-size: 14px;
	line-height: 22px;
	text-align: left;
	margin-top: 10px;
	font-style: italic;
}
.content-slider__item {
	font-size: 0;
	line-height: 0;
}
.content-slider__arrow {
	padding: 0;
	width: 48px;
	height: 48px;
	border: 0;
	background: #2e2d2c;
	position: absolute;
	bottom: 0;
	z-index: 10;
	outline: 0;
	svg {
		width: 9px;
		height: 14px;
		fill: #FFF;
	}
	&:hover {
		background: #4d4d4f;
	}
}
.content-slider__arrow--prev {
	left: 503px;
	@media (max-width:1200px) {
		left: 0;
		bottom: -48px;
	}
}
.content-slider__arrow--next {
	left: 551px;
	@media (max-width:1200px) {
		left: 48px;
		bottom: -48px;
	}
}
.content-slider--news {
	.content-slider__arrow {
		top: 50%;
		bottom: auto;
		margin-top: -24px;
	}
	.content-slider__arrow--prev {
		left: 0;
	}
	.content-slider__arrow--next {
		left: auto;
		right: 0;
	}
}

.week-selector {
	position: relative;
	float: right;
	margin-top: 46px;
	width: 364px;
}
.week-selector__current {
	width: 315px;
	height: 48px;
	background: #FFF;
	box-shadow: 0 3px 5px rgba(0, 36, 71, 0.12);
	line-height: 48px;
	padding: 0 20px;
	float: left;
	&:hover ~ .week-selector__dropdown {
		display: block;
	}
}
.week-selector__dropdown {
	display: none;
	position: absolute;
	width: 315px;
	box-shadow: 0 2px 30px rgba(0, 0, 0, 0.24);
	z-index: 20;
	left: 23px;
	top: 0;
	padding-top: 48px;
	&:hover {
		display: block;
	}
}
.week-selector__item {
	height: 48px;
	line-height: 48px;
	background: #FFF;
	padding: 0 18px;
}

.week-selector__button {
	width: 9px;
	height: 18px;
	background:none;
	border: 0;
	float: left;
	padding: 0;
	margin: 15px 14px;
	svg {
		width: 9px;
		height: 14px;
		fill: #d5d5d5;
	}
	&--prev {
		margin-left: 0;
	}
	&--next {
		margin-right: 0;
	}
	&:hover {
		svg {
			fill: #979696;
		}
	}
}

.content-slider {
	img {
		display: block;
		width: 100%;
	}
}

.header__bottom-line {
	.login-bt {
		margin-right: 25px;
	}	
}

.show-full-menu {
	border: 0;
	background: none;
	padding: 0;
	float: right;
	margin: 18px 0 0 30px;
	svg {
		width: 29px;
		height: 19px;
		fill:#4d4d4f;
	}
}

@import "components/text/text";
@import "components/header";
@import "components/footer";
@import "components/logo";
@import "components/main-slider";
@import "components/search";
@import "components/top-social";
@import "components/lang-selector";
@import "components/buttons";
@import "components/radiobutton";
@import "components/checkboxes";
@import "components/forms";
@import "components/text";
@import "components/textarea";
@import "components/label";
@import "components/colored-menu";
@import "components/main-menu";
@import "components/news-preview";
@import "components/pink-menu";
@import "components/important-information";
@import "components/advantages-short";
@import "components/e-calendar";
@import "components/breadcrubs";
@import "components/persons";
@import "components/mission";
@import "components/blue-menu";
@import "components/science-and-innovation";
@import "components/latest-events";
@import "components/pager";
@import "components/questions";
@import "components/decorated-title";
@import "components/rectorat";
@import "components/sidebar-menu";
@import "components/veterans";
@import "components/download-list";
@import "components/sorting";
@import "components/careers";
@import "components/questionnaires";
@import "components/about-numbers";
@import "components/accortdion";
@import "components/univer-careers";
@import "components/interview";
@import "components/large-decorated-link";
@import "components/styled-link";
@import "components/rector-biography";
@import "components/fill-questionnaire-form";
@import "components/contacts-block";
@import "components/experts";
@import "components/questionnaire";
@import "components/mobile-blue-menu";
@import "components/select";
@import "components/full-menu";
@import "components/gallery";
@import "components/olympics";
@import "components/incoming-menu";
@import "components/incoming-title";
@import "components/incoming-rating";
@import "components/decrated-link";
@import "components/income-block-link";
@import "components/faculty";
@import "components/ege-bonus";
@import "components/academic-department";
@import "components/open-day-block";
@import "components/schedule";
@import "components/calculator";
@import "components/404.scss";
@import "components/locations-widget";
@import "components/business-advantages";
@import "components/faculty-roadmap";
@import "components/share";
@import "components/file-dield";

.contacts-pp-left {
	width: 153px;
	float: left;
	margin-right: 25px;
	img {
		width: 100%;
	}
	@media (max-width: 767px) {
		display: none;
	}
}
.contacts-pp-right {
	overflow: hidden;
}
.contacts-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	@media (max-width: 767px) {
		display: block;
	}
	.rectorat-person__info {
		width: 45%;
		margin-top: 30px;
		@media (max-width: 767px) {
			width: 100%;
		}
	}
}
.contacts-pp-title {
	color: #2e2d2c;
	font-size: 36px;
	font-weight: 700;
	line-height: 35px;
	&:after {
		margin-top: 20px;
		content: "";
		width: 38px;
		height: 3px;
		background-color: #ed1746;
		display: block;
	}
	@media (max-width: 767px) {
		font-size: 29px;
	}
}

.fc-view-container {
	background: #FFF;
}
.fc th, .fc td {
	border-color: #ededed;
}
.fc-event {
	border-radius: 0;
	// border: 0;
	// background-color: #004182;
	// &:hover {
	// 	background-color: #44c8f5;
	// }
}
.fc-day-grid-event {
	padding: 2px;
}
.fc  {
	.fc-button {
		border: 0;
		border-radius: 0;
		background: #004182;
		color: #fff;
		text-shadow: none;
		box-shadow: none;
		height: 40px;
		padding: 0 15px;
		&.fc-state-active, &:hover {
			background: #44c8f5;
		}
	}
}

.fluid-left-col {
	float: left;
	margin: 0 30px 0 15px;
	width: 360px;
}
.fluid-right-col {
	float: left;
	width: calc(100vw - 446px);
}
.none-p {
	padding:0;
}
.mass-media-link {
	margin-bottom: 20px;
}
.years-tabs__caption {
	display: inline-block;
	margin: 0 20px 0 0;
	padding:0;
	font-size:rem(22);
	font-weight: 600;
	text-align: center;
	color: #004282;
	&--small {
		font-size: rem(19);
		margin-right: 17px;
	}
	&--active {
		color: #2e2d2c;
		font-size:rem(34);
	}
	&--small.years-tabs__caption--active {
		font-size:rem(24);
	}
	&:hover {
		color: #ec1646;
	}
}
// .news-block {
// 	margin:0 0 60px 0;
// }
// .news-img {
// 	width:100%;
// 	img {
// 	width:100%;
// 	-webkit-filter: grayscale(100%);
// 	-moz-filter: grayscale(100%);
// 	-ms-filter: grayscale(100%);
// 	-o-filter: grayscale(100%);
// 	filter: grayscale(100%);
// 	filter: gray;
// 	&:hover {
// 		-webkit-filter: none;
// 		-moz-filter: none;
// 		-ms-filter: none;
// 		-o-filter: none;
// 		filter: none;
// 		filter: none; 
// 	}		
// 	}	
// }
// .news-date {
// 	color:#989897;
// 	font-size:16px;
// 	margin:23px 0 25px 0;
// 	text-align:left;
// }
// .news-title {
// 	text-align:left;
// }
// .news-title a{
// 	font-size:34px;
// 	color:#2e2d2c;
// 	line-height:34px;
// 	font-weight:bold;	
// 	&:hover {
// 		color:#337ab7;
// 	}		
// }
// .news-intro {
// 	margin:35px 0 0 0;
// 	color:#2e2d2c;
// 	font-size:15px;
// 	text-align:left;	
// }
.white-bg {
	background:white;
	width:100%;
	padding:65px 0;
	margin:0 0 -100px 0;
}
// .all-news {
// 	text-align:Center;
// 	border-bottom:2px solid #e5e5e5;
// 	.news-date {margin:15px 0 15px 0;}
// 	.news-intro {margin:15px 0 0 0;}
// 	.news-title a {
// 		font-size:22px;
// 		line-height:22px;
// 	}
// 	.news-block {
// 		display:inline-block;
// 		float:none;
// 		width:calc(49% - 10px);
// 		vertical-align:top;
// 	}
// 	.news-block:nth-child(1n){
// 		margin:0 10px 70px 0;
// 	}
// 	.news-block:nth-child(2n){
// 		margin:0 0 70px 10px;
// 	}	
// }
// .pager {
// 	width:100%;
// 	text-align:Center;
// 	ul {padding:0;}
// }
// .pager ul li {
// 	display:inline-block;
// 	margin:0 5px;
// 	width:40px;
// 	height:40px;	
// 	line-height:40px;	
// 	.active {
// 		background:#ed1847;
// 		color:white;
// 		-moz-border-radius: 20px;
// 		-webkit-border-radius: 20px;
// 		border-radius: 20px;
// 	}
// 	a {
// 		color:#2e2d2c;
// 		font-size:16px;		
// 		display:Block;	
// 		}
// 	}
// .pager ul li a:hover {
// 	background:#ed1847;
// 	color:white;	
// 	-moz-border-radius: 20px;
// 	-webkit-border-radius: 20px;
// 	border-radius: 20px;	
// }
.header-info {
	font-size:22px;
	line-height:22px;
	color:#2e2d2c;
	font-weight:bold;
	float:left;
	width:100%;
	margin:0 0 25px 0;
}
.info-block {
	margin:25px 0 0 0;
	border-bottom:1px solid #e6e7e9;
	padding:0 0 23px 0;
}
.photo-gal .info-block {
	border-bottom:none;
	padding:0;
}
.info-img {
	width:100%;
	img {width:100%;}
}
.info-date {
	color: #989897;
	font-size: 16px;
	margin: 0 0 10px 0;
	text-align: left;	
}
.info-title a {
	color:#2e2d2c;
	font-size:16px;
	font-weight:bold;
	&:hover {
		color:#337ab7;
	}	
}
.all-ob {
	width:100%;
	border:1px solid #e6e7e9;
	padding:18px 0;
	font-size:14px;
	text-align:center;
	display:block;
	margin:20px 0 20px 0;
	text-transform:uppercase;
	color:#2e2d2c;
	&:hover {
		color:#337ab7;
		border:1px solid #337ab7;		
	}	
}
.journal {
	margin:0 0 20px 0;
	float:left;
}
.journal a {
	float:left;
	margin:0 0 40px 0;	
	span {
		display:block;
		margin:20px 0 0 0;
		color:#2e2d2c;
		font-weight:bold;
		&:hover {
			color:#eb1e47;
		}	
	}		
}
.journal a:last-child img {
	box-shadow:none;
}
.yt-channel {
	display: block;
	width: 231px;
	height: 230px;
	background: url(../img/TV.jpg) 0 0 no-repeat;
	margin: 0 auto 40px;
	outline: 0;
	&:hover {
		background-position: -231px 0;	
	}
}
.journal a img {
	width:100%;
	-webkit-box-shadow: 0px 0px 19px -1px rgba(0,0,0,0.59);
	-moz-box-shadow: 0px 0px 19px -1px rgba(0,0,0,0.59);
	box-shadow: 0px 0px 19px -1px rgba(0,0,0,0.59);
}
.oblozka {margin:50px 0;width:240px;float:left;}
.download {
	width:100%;
	border:1px solid #e6e7e9;
	padding:16px 0;
	font-size:14px;
	text-align:center;
	display:block;
	margin:16px 0;
	text-transform:uppercase;
	color:#0f5499;
	font-weight:bold;
		&:hover {
			border:1px solid #0f5499;
		}		
}
.download span {display: block;background: url(../img/download.png) right no-repeat;width: 95px;margin: auto;padding: 0 20px 0 0;}
.opis-book {float:left;width:calc(100% - 240px);margin:50px 0 0 0;list-style-type:none;}
.opis-book li {margin:0 0 40px 0;font-size:15px; line-height:15px;}
.opis-book li span {display:block;width:100%;color:#2e2d2c;font-size:22px;font-weight:bold;margin:0 0 20px 0;line-height:22px;}
.opis-book li span i {color:#cccdce;font-style:normal;padding:0 0 0 10px;}
.oblozka img {
	-webkit-box-shadow: 0px 0px 19px -1px rgba(0,0,0,0.59);
	-moz-box-shadow: 0px 0px 19px -1px rgba(0,0,0,0.59);
	box-shadow: 0px 0px 19px -1px rgba(0,0,0,0.59);
}

.journal-all {
	width:100%;
	text-align: left;
	margin:40px 0;
	@media(max-width: 1200px) {
		text-align: center;
	}
}
.journal-all a {
	display:inline-block;
	width:24.1%;
	margin:0 3px 40px 3px;
	img {
		-webkit-box-shadow: 0px 0px 19px -1px rgba(0,0,0,0.59);
		-moz-box-shadow: 0px 0px 19px -1px rgba(0,0,0,0.59);
		box-shadow: 0px 0px 19px -1px rgba(0,0,0,0.59);			
	}
	span {
		text-align:left;
		color:#2e2d2c;
		font-weight:bold;
		margin:20px 0 0 0;
		display:block;
		padding:0 3px;
		&:hover {
			color:#eb1e47;
		}					
	}
}		

.construction-page {
	height: 553px;
	background: url(../img/construction.png) center no-repeat;
	@media(max-width: 1024px) {
		height: calc(100vh - 340px);
	}
	@media(max-width: 767px) {
		height: 50vh;	
		background-size: 140%;
	}
}
.press-slider {
	.news-preview {
		width: 340px;
		padding: 0 10px;
		.news-preview__title {
			font-size: rem(16);
			line-height: normal;
		}
		.news-preview__photo {
			margin-bottom: 20px;
		}
	}
	.content-slider__arrow--prev {
		left: 0;
	}
	.content-slider__arrow--next {
		left: auto;
		right: 0;

	}
	.content-slider__arrow {
		bottom: auto;
		top: 60px;
	}
}

.header-contact-item {
	float: left;
	font-size: 17px;
	line-height: 24px;
	width: 244px;
	& > * {
		margin-bottom: 10px;
	}
	&:first-child {
		width: 356px;
	}
}
.header-contact-item__title {
	font-weight: 700;
}
.header-contact-item__fio {

}
.header-contact-item__post {

}
.header-contact-item__phone {
	font-weight: 700;
}
.contacts-icon-star {
	width: 42px;
	height: 41px;
	fill: #0a0a0a;
	margin-right: 20px;
	vertical-align: -10px;
}
.contacts-icon-location2 {
	width: 34px;
	height: 45px;
	fill: #0a0a0a;
	margin-right: 25px;
	vertical-align: middle;
}
.contacts-icon-income-info {
	width: 46px;
	height: 48px;
	fill: #0a0a0a;
	vertical-align: -15px;
	margin-right: 15px;
}
.contacts-tbl {
	width: 100%;
	font-size: 15px;
	td {
		vertical-align: top;
	}
	td:first-child {
		font-weight: 700;
		width: 140px;
		text-align: right;
		padding-right: 20px;
		padding-bottom: 10px;
	}
}
.extrabudgetary-requisites {
	padding: 30px 40px;
	background: #f7f7f7;
	font-size: 15px;
	b {
		display: block;
	}
}
.extrabudgetary-requisites__title {
	font-size: 22px;
	line-height: 26px;
	font-weight: 700;
}
.extrabudgetary-requisites__tbl {
	width: 100%;
	td {
		vertical-align: top;
	}
	td:first-child {
		font-weight: 700;
		width: 65px;
		text-align: right;
		padding-right: 12px;
	}
}

@media (min-width: 4000px) {
	// .colored-menu__item, .main-menu__link {
	// 	font-size: 5px;
	// }
	.scale-font, .lang-selector, .show-full-menu {
		display: none;
	}
	.main-menu__link {
		padding: 0 5px;
	}
}
@media (max-width: 1200px) {
	.journal-all a { 
		width:49%;
		margin:0 0 30px 0;
	}
	.journal-all a span {
		text-align:Center;		
	}	
	.scale-font {
		margin-top: 21px;
	}
	.header {
		.show-full-menu {
			margin: 19px 0 0 30px;
		}
	}
	.tab-head-cont {
		.is-open {background:white;}
	}	
}
@media (max-width: 768px) {
.header-info {
	margin:0;
}
.oblozka {
	float:none;
	margin:20px auto 20px auto;
}
.opis-book {
	float:left;
	width:100%;
	padding:0;
}
	.journal {margin:40px 0;text-align:center;width:100%;}
	.journal a {
		width:49%;
		display:inline-block;
		vertical-align:top;
		float:none;		
		text-align:center;
		img {width:auto;}
	}
	.photo-gal .info-block  {width:49%;display:inline-block; vertical-align:top;}
	.vazno {display:none;}
	.news-date {margin:15px 0 15px 0;}
	.news-intro {margin:15px 0 0 0;}
	.news-title a {
		font-size:22px;
		line-height:22px;
	}	
	// .all-news .news-block {
	// 	width:100%;	
	// 	margin:0 0 30px 0 !important;
	// }

	.latest-events__calendar-container {
		position: static;
		.calendar {
			position: static;
			transform: translate(0, 0);
		}
	}
	.tabs-caption {
		overflow: hidden;
		display: block;
		height: auto;
		.blue-menu__item {
			width: 100%;
			display: block;
		}
	}
	.partners-list {
		justify-content: center;
	}
	// .week-selector {
	// 	position: relative;
	// 	margin-top:0;
	// 	float:left;
	// 	margin:0 0 10px 0;
	// 	width:318px;
	// 	.week-selector__button {display:none;}
	// 	.week-selector__dropdown {left:0;}
	// }
}
@media (max-width: 530px) {
	.photo-gal .info-block  {width:100%}	
	.journal {margin:20px 0;}	
	.journal a {
		width:100%;
		margin:10px 0;
	}	
	.journal-all a { 
		width:100%;
		margin:0 0 30px 0;
	}	
	.content-slider {
		.content-slider__img-box {
			height: 245px;
		}
	}
	.content-slider--news .content-slider__arrow {
		margin-top: -78px;
	}
}
