.questionnaire__description {
	font-size: rem(18);
	line-height: rem(24);
}
.questionnaire__group {
	margin-top: 24px;
	background-color: $pale-grey;
}
.questionnaire__group-title {
	height: 90px;
	line-height: rem(90);
	font-size: rem(24);
	font-weight: bold;
	padding-left: 25px;
	border-bottom: 1px #dcdddf solid;
	margin-bottom: 48px;
}
.questionnaire__group-container {
	
}
.questionnaire__group-note {
	font-size: rem(18);
	line-height: rem(28);
	margin-bottom: 60px;
	margin-right: 15px;
}
.questionnaire__flex-row {
	font-size: 0;
	display: flex;
	justify-content: space-between;
}

@media (max-width: 767px) {
	.questionnaire__group-title {
		font-size: rem(16);
		height: 63px;
		line-height: 63px;
	}
	.questionnaire__group-note {
		font-size: rem(16);
		line-height: rem(19);
	}
}