.mobile-blue-menu {
    position: relative;
    margin: 0 12px;
}
.mobile-blue-menu__current {
    &:after {
        content: "";
        position: absolute;
        width: 48px;
        height: 48px;
        top: 0;
        right: 0;
        background: #004282 url(../img/arrow-down.svg) center no-repeat;
        background-size: 14px 9px;
    }
    span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
    }
    &--active {
        &:after {
            transform: rotate(-180deg);
        }
    }
}
.mobile-blue-menu__current, .mobile-blue-menu__item {
    height: 48px;
    background: #44c8f5;
    line-height: 48px;
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0 60px 0 20px;
}
.mobile-blue-menu__dropdown {
    position: absolute;
    left: 0;
    top: 48px;
    width: 100%;
    z-index: 3;
    display: none;
}
.mobile-blue-menu__item {
    display: block;
}