.latest-events {
	position: relative;
	min-height: 360px;
}
.latest-events__calendar-container {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	background-color: $pale-grey;
	min-height: 360px;
}
.latest-events__item {
	margin-bottom: 30px;
}
.latest-events__item-date {
	color: $cement;
	margin-bottom: 10px;
}
.latest-events__item-name {
	font-size: rem(18);
	font-weight: bold;
}
.latest-events__item-info {
	margin-top: 20px;
}
@media (max-width: 1200px) {
	.latest-events__calendar-container {
		overflow: hidden;
	}
}
@media (max-width: 767px) {
	.latest-events__calendar-container {
		min-height: 0;
		.calendar {
			margin: 10px auto 0;
		}
	}
}