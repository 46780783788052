.decrated-link {

}
.decrated-link__item {
  font-size: rem(16);
  font-weight: 700;
  line-height: 24px;
  color: #2e2d2c;
  display: block;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  padding: 15px 100px 15px 0;
  &:after {
    content: "";
    width: 14px;
    height: 9px;
    background: url(../img/arrow-down-black.svg);
    position: absolute;
    right: 0px;
    top: 23px;
    transform: rotate(-90deg);
  }
}