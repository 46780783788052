.rector-biography {

}
.rector-biography__item {
	margin-bottom: 45px;
}
.rector-biography__date {
	font-size: rem(22);
	margin-bottom: 15px;
}
.rector-biography__name {
	font-size: rem(24);
	font-weight: bold;
	margin-bottom: 20px;
	padding-bottom: 18px;
	position: relative;
	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 45px;
		height: 3px;
		background-color: #ed1746;
	}
}
.rector-biography__text {

}