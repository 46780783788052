.experts {
	background-color: $pale-grey;
	padding-bottom: 120px;
	margin-top: 60px;
}
.expert {
	width: 470px;
	padding: 48px 0 40px 0;
	&--odd {
		float: left;	
	}
	&--even {
		float: right;
	}
}
.expert__photo {
	float: left;
	margin-right: 25px;
	
}
.expert__name {
	font-size: rem(18);
	font-weight: 700;
	line-height: rem(19);
	padding-top: 25px;
	padding-bottom: 10px;
	a {
		color: #2e2d2c;
		&:hover {
			color: #ed1847;
		}
	}
}
.expert__post {
	margin-bottom: 30px;
}
.expert__comment {
	padding: 0 85px;
	position: relative;
	font-weight: bold;
	line-height: rem(20);
	&:before, &:after {
		content: "";
		left: 0;
		top: 50%;
		position: absolute;
		width: 67px;
		height: 43px;
		background: url(../img/opornii-vuz_quote-left.svg);
		transform: translate(0, -50%);
	}
	&:after {
		background: url(../img/opornii-vuz_quote-right.svg);
		left: auto;
		right: 0;
	}
}
@media (max-width: 1200px) {
	.rectorat-grid {
		display: block;
	}
	.rectorat-grid__col {
		width: 100%;
		border: 0;
	}
	.expert--odd, .expert--even {
		float: none;
	}
	.expert__comment {
		margin-left: 180px;
	}
	.expert {
		width: 100%;
		border-bottom: 1px solid #e6e7e9;
	}
	.experts {
		padding-bottom: 0;
	}
}
@media (max-width: 767px) {
	.expert {
		text-align: center;
	}
	.expert__photo {
		float: none;
		margin: 0;
	}
	.expert__post {
		margin-bottom: 0;
	}
	.expert__comment {
		margin-left: 0;
		padding: 0 38px;
		&:before, &:after {
			width: 24px;
			height: 16px;
		}
		&:after {
			background: url(../img/opornii-vuz_quote-right.svg);
		}
	}
}