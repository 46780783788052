.careers {

}
.careers__title {
	margin-top: 0;
}
.careers__item {
	padding: 48px 0 59px 0;
	border-top: 1px solid #e5e5e5;
	&:last-child {
		border-bottom: 1px solid #e5e5e5;
	}
	&--preview {
		padding: 20px 0 25px 0;
		&:last-child {
			border-bottom: 0;
		}
		&:first-child {
			border-top: 0;
			padding-top: 0;
		}
		.careers__post-name {
			font-size: rem(18);
			padding-bottom: 18px;
		}
		.careers__city {
			margin-bottom: 0;
		}
	}
}
.careers__city {
	color: $cement;
	margin-bottom: 17px;
}
.careers__post-name {
	font-size: rem(24);
	font-weight: bold;
	position: relative;
	padding-bottom: 25px;
	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 43px;
		height: 3px;
		background-color: #ed1746;
	}
}
.careers__info {
	margin: 20px 0 10px 0;
}
.careers__salary {
	font-size: rem(18);
	font-weight: bold;
}
.careers__serach {
	position: relative;
	margin-bottom: 25px;
}
.careers__serach-submit {
	border: 0;
	width: 48px;
	height: 48px;
	padding: 0;
	background:none;
	border-radius: 0;
	position: absolute;
	top: 0;
	right: 0;
	svg {
		width: 20px;
		height: 20px;
		fill: $black;
		vertical-align: middle;
	}
	&:hover {
		svg {
			fill: $pinky-red;
		}
	}
}
.careers__autocomplete {
	display: none;
	z-index: 5;
	max-height: 200px;
	overflow: scroll;
	position: absolute;
	width: 100%;
	border: solid 1px $silver;
	border-top: 0;
	background: #FFF;
	position: absolute;
	left: 0;
	top: 47px;
	box-shadow: 0 5px 8px rgba(0, 36, 71, 0.12);
	&-item {
		height: 48px;
		line-height: 48px;
		padding: 0 20px;
		&:hover {
			background-color: $pale-grey;
			color: $pinkish-red-two;
			cursor: pointer;
		}
	}
}