.logo-box {
	width: 263px;
	height: 120px;
	background-color: $darkish-blue;
	float: left;
	.logo {
		display: block;
		width: 190px;
		height: 90px;
		margin: 15px 0 12px 0;
		background: url(../img/logo.svg) no-repeat;
	}
	.to-information {
		color: #ffffff;
		font-size: rem(13);
		letter-spacing: -.3px;
		&:hover {
			text-decoration: underline;
		}
	}
}
.mobile-header {
	.logo {
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		position: absolute;
		width: 112px;
		height: 52px;
		background: url(../img/logo.svg) no-repeat;
	}
}
@media (max-width:1200px) {
	.logo-box {
		width: 190px;
		height: 120px;
		.logo {
			width: 149px;
			height: 69px;
			margin: 25px auto 0;
		}
	}
}