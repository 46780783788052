.latest-news {
	margin-bottom: 58px;
}
.news-preview {
	margin-bottom: 47px;
}
.news-preview__photo {
	display: block;
	img {
		display: block;
		width: 100%;
		height: auto;
		filter: grayscale(100%);
	}
	&:hover {
		img {
			filter: grayscale(0);
		}
	}
}
.news-preview__postdate {
	font-size: rem(16);
	color: $cement;
	margin: 25px 0 19px 0;
	&--left {
		float: left;
	}
}
.news-preview__autor {
	font-size: rem(16);
	color: $cement;
	margin: 25px 0 19px 0;
	&--left {
		float: left;
		margin-right: 30px;
	}
}
.news-preview__title {
	font-size: rem(24);
	font-weight: bold;
	color: $black;
}
.news-preview__anonce {
	margin-top: 25px;
}