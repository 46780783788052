.business-advantages {
  background-color: #44c8f5;
  padding: 40px 40px 0;
  margin: 40px 0;
  overflow: hidden;
}
.business-advantages__flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.business-advantages__item {
  color: #ffffff;
  width: 230px;
  margin-bottom: 40px;
  &--single {
    margin: 0 auto 40px;
    max-width: 390px;
    width: 100%;
    display: flex;
    .business-advantages__icon {
      margin-right: 41px;
      width: 53px;
    } 
    .business-advantages__name {
      flex: 1 0;
      margin: 0;
    }
  }
}
.business-advantages__icon {
  display: block;
  fill: #FFF;
  height: 51px;
}
.business-advantages__name {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin: 10px 0 10px 0;
}
.business-advantages__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.business-advantages__icon--business-1 {
  width: 50px;
}
.business-advantages__icon--business-2 {
  width: 47px;
}
.business-advantages__icon--business-3 {
  width: 47px;
}
.business-advantages__icon--business-4 {
  width: 47px;
}
.business-advantages__icon--business-5 {
  width: 50px;
}
.business-advantages__icon--business-6 {
  width: 50px;
}

.business-advantages__icon--business-7 {
  width: 50px;
}
.business-advantages__icon--business-8 {
  width: 37px;
}
.business-advantages__icon--business-9 {
  width: 53px;
  height: 55px;
}
.business-advantages__icon--business-10 {
  width: 37px;
}
.business-advantages__icon--business-11 {
  width: 33px;
  height: 45px;
}

.business-advantages__icon--business-12 {
  width: 49px;
}
.business-advantages__icon--business-13 {
  width: 48px;
}
.business-advantages__icon--business-14 {
  width: 42px;
}
.business-advantages__icon--business-15 {
  width: 51px;
}
.business-advantages__icon--business-16 {
  width: 44px;
}
.business-advantages__icon--business-17 {
  width: 44px;
}

.business-callback {
  padding: 40px;
  background: #f5f6f8;
}