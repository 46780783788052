.styled-button {
	border: 0;
	background: none;
	color: $black;
	text-align: center;
	font-family: "Proxima Nova";
	font-size: rem(15);
	font-weight: 400;
	line-height: 50px;
	text-transform: uppercase;
	letter-spacing: 0.6px;
	width: 243px;
	height: 50px;
	padding: 0;
	appearance: none;
	display: inline-block;
	border-radius: 0;
	&--w100 {
		width: 100%;
	}
}
.styled-button--bold {
	font-weight: 700;
}

.styled-button--red {
	border: 1px solid $pinkish-red-two;
	
		&:hover {
			color: $black;
			border-color: $pinky-red;
		}
	
	&.styled-button--solid {
		color: #FFF;
		background:$pinkish-red-two;
		
			&:hover {
				color: #FFF;
				border-color: $pinky-red;
				background:$pinky-red;
			}
		
	}
}

.styled-button--lightgray {
	border: 1px solid $silver;
	color: $black;
	
		&:hover {
			color: $black;
			border-color: $silver-two;
		}
	
	&.styled-button--solid {
		background:$silver;
		
			&:hover {
				background:$silver-two;
				border-color: $silver-two;
			}
		}
	
}

.styled-button--darkgray {
	border: 1px solid $silver-three;
	color: $black;
		&:hover {
			color: $black;
			border-color: $silver-two;
		}
	&.styled-button--solid {
		background:$silver-three;
			&:hover {
				background:$silver-two;
				border-color: $silver-two;
			}
	}
}

.styled-button--white {
	border: 1px solid #a1e4f9;
	color: #FFF;
		&:hover {
			color: #FFF;
			border-color: #FFF;
		}
	&.styled-button--solid {
		background:#a1e4f9;
			&:hover {
				color: $black;
				background:#FFF;
				border-color: #FFF;
			}
	}
}

.styled-button--center {
	display: block;
	margin: 0 auto;
}
.styled-button--right {
	float: right;
}
.styled-button--rector {
	margin-top: 40px;
}

.show-question-form {
	margin-top: 50px;
}

@media (max-width: 767px) {
	.styled-button {
		margin: 20px auto 0;
		display: block;
	}
}