.pink-menu {
	height: 72px;
	line-height: 72px;
	background-color: $pinkish-red-two;
	overflow: hidden;
	position: relative;
}
.pink-menu__title {
	color: #FFF;
	font-size: rem(24);
	font-weight: 700;
	margin-left: 25px;
}
.pink-menu__link {
	color: #FFF;
	font-size: rem(18);
	margin-right: 45px;
	position: relative;
	span {
		display: inline-block;
		line-height: normal;
		vertical-align: middle;
	}
	&:after {
		content: "";
		position: absolute;
		width: 4px;
		height: 4px;
		background-color: #ffffff;
		border-radius: 2px;
		top: 50%;
		margin-top: -2px;
		right: -23px;
	}
	&:last-child {
		margin-right: 0;
		&:after {
			display: none;
		}
	}
	&:hover {
		color: $purple-brown;
	}
}
.pink-menu__introductory-campaign-icon {
	width: 47px;
	height: 60px;
	vertical-align: -23px;
}
.pink-menu__media-icon {
	width: 65px;
	height: 40px;
	vertical-align: middle;
}
.pink-menu__icon-left {
	position: absolute;
	width: 9px;
	height: 14px;
	fill: #fff;
	right: 20px;
	top: 50%;
	margin-top: -7px;
	display: none;
}
@media (max-width: 1200px) {
	.pink-menu__links {
		display: flex;
		height: 72px;
		align-items: center;
	}
	.pink-menu__link {
		line-height: normal;
	}
}
@media (max-width: 767px) {
	.pink-menu {
		overflow: visible;
		z-index: 3;
		&:hover {
			.pink-menu__links {
				display: block;
			}
		}
	}	
	.pink-menu__links {
		display: none;
		height: auto;
		position: absolute;
		left: 0;
		top: 72px;
		width: 100%;
		background-color: $pinkish-red-two;
		padding: 15px;
	}
	.pink-menu__link {
		display: block;
		padding: 15px 0;
		&:after {
			display: none;
		}
		br {
			display: none;
		}
	}
	.pink-menu__icon-left {
		display: block;
	}
}