h1, h2, h3, h4 {
	margin: 40px 0 23px 0;
	padding: 0;
}
h1 {
	font-size: rem(48);
	font-weight: 700;
	line-height: 43px;
}
h2 {
	font-size: rem(36);
	font-weight: 700;
	line-height: 36px;
}
h3 {
	font-size: rem(24);
	font-weight: 700;
	line-height: 28px;
}
h4 {
	font-size: rem(18);
	font-weight: 700;
	line-height: 22px;
}
ul.styled-list {
	list-style: none;
	padding-left: 0;
	& > li {
		padding-left: 16px;
		position: relative;
		&:before {
			content: "";
			left: 0;
			top: 9px;
			width: 6px;
			height: 6px;
			background: $pinkish-red-two;
			position: absolute;
			border-radius: 3px;
		}
	}
}
ol.styled-list {
	list-style: none;
	padding-left: 0;
	& > li {
		counter-increment: step-counter;
		&:before {
			content: counter(step-counter) ". ";
			font-weight: bold;
			color: $pinkish-red-two;
		}
	}
	ol, ul {
		margin-left: 20px;
	}
}
.text-block {
	img {
		max-width: 100%;
	}
	h2 {
		margin-top: 0;
	}
	ul {
		list-style: none;
		padding-left: 0;
		& > li {
			padding-left: 16px;
			position: relative;
			&:before {
				content: "";
				left: 0;
				top: 9px;
				width: 6px;
				height: 6px;
				background: $pinkish-red-two;
				position: absolute;
				border-radius: 3px;
			}
		}
		ol, ul {
			margin-left: 20px;
		}
	}
	ol {
		list-style: none;
		padding-left: 0;
		& > li {
			counter-increment: step-counter;
			&:before {
				content: counter(step-counter) ". ";
				font-weight: bold;
				color: $pinkish-red-two;
			}
		}
		ol, ul {
			margin-left: 20px;
		}
	}

	table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid #ededed;
		border-top: 3px solid $pinkish-red-two;
		// overflow: auto;
		// display: block;
		th {
			background: $pale-grey;
			padding: 25px;
			text-align: left;
		}
		td {
			padding: 25px;
			border-bottom: 1px solid #ededed;
		}
	}

	table.items {
		font-size: rem(13);
		line-height: 18px;
		th {
			padding: 5px 10px;
		}
		td {
			padding: 5px 10px;
		}
	}
}
.scrolled-table {
	width: 100%;
	overflow: hidden;
	position: relative;
}
.scrolled-table__button {
	position: absolute;
	width: 32px;
	height: calc(100% - 3px);
	top: 3px;
	z-index: 10;
	border: 0;
	background: #e6e7e9;
	padding: 0;
	outline: 0;
	svg {
		top: 200px;
		left: 50%;
		margin-left: -4px;
		position: absolute;
		width: 9px;
		height: 14px;
		fill: red;
	}
}
.scrolled-table__button--next {
	right: 0;
}
.scrolled-table__button--prev {
	left: 0;
	display: none;
}

@media (max-width: 1200px) {
	
}
@media (max-width: 767px) {
	h1 {
		font-size: rem(30);
		font-weight: 700;
		line-height: 34px;
		text-align: center;
	}
	h2 {
		font-size: rem(28);
		font-weight: 700;
		line-height: 34px;
	}
	h3 {
		font-size: rem(24);
		font-weight: 700;
		line-height: 28px;
	}
	h4 {
		font-size: rem(18);
		font-weight: 700;
		line-height: 22px;
	}
	table.one-mob-col {
		td:first-child {
			text-align: center;
		}
		td {
			display: block;
			border-bottom: 0;
		}
	}
	.page-title {
		text-align: center;
	}
}
