textarea {
	width: 100%;
	height: 120px;
	box-shadow: 0 5px 8px rgba(0, 36, 71, 0.12);
	background-color: #ffffff;
	padding: 18px;
	outline: 0;
	appearance: none;
	color: $black;
	font-size: rem(16);
	font-weight: 400;
	border: 1px #FFF solid;
	resize: none;
	&:focus {
		border-color: $bright-cyan;
	}
}