.interview {
	background-color: $darkish-blue;
	padding: 50px;
	color: #FFF;
	margin-bottom: 40px;
}
.interview__list {
	overflow: hidden;
}
.interview__title {
	margin: 0 0 5px 0;
}
.interview__item {
	padding: 25px 0;
	border-bottom: 1px solid #002e5b;
	&:last-child {
		border-bottom: 0;
	}
}
.interview__date {
	opacity: 0.6;
}
.interview__name {
	font-size: rem(18);
	font-weight: bold;
	line-height: 1.22;
	color: #ffffff;
	&:hover {
		color: $bright-cyan;
	}
}
.interview__to-all {
	font-size: rem(15);
	font-weight: bold;
	letter-spacing: 0.6px;
	color: $bright-cyan;
	text-transform: uppercase;
	&:hover {
		color: #ffffff;
	}
}
@media(max-width:1200px) {
	.interview {
		text-align: center;
	}
	.interview__item {
		text-align: left;
		float: left;
		width: 50%;
		padding-left: 18px;
		&:first-child {
			border: 0;
			padding-right: 10px;
			padding-left: 0;
			border-right: 1px solid #002e5b;
		}
	}
	.interview__title {
		text-align: left;
	}
	.interview__list {
		margin-bottom: 20px;
	}
}
@media(max-width:767px) {
	.interview {
		padding: 20px;
	}
	.interview__item {
		float: none;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		&:first-child {
			padding-left: 0;
			padding-right: 0;
			border-right: 0;
			border-bottom: 1px solid #002e5b;
		}
	}
}