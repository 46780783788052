.incoming-rating {
  overflow: hidden;
  & > img {
    float: right;
    @media (max-width: 767px) {display: none;}
  }
}
.incoming-rating__col {
  float: left;
  width: 330px;
}
.incoming-rating__item {
  overflow: hidden;
  margin-bottom: 48px;
}
.incoming-rating__num {
  width: 107px;
  margin-right: 25px;
  text-align: right;
  float: left;
}
.incoming-rating__big {
  font-size: rem(90);
  font-weight: 700;
  line-height: 58px;
  &--78 {
    font-size: rem(78);
  }
}
.incoming-rating__small {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  display: inline-block;
}
.incoming-rating__name {
  color: #004282;
  font-size: 15px;
  line-height: 22px;
  overflow: hidden;
  display: block;
}
.incoming-rating__col--w50 {
  width: 50%;
}