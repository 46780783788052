.ac-de-title {
  margin-bottom: 40px;
  span {
    display: block;
    font-size: rem(34);
  }

}

.ac-de-structure {
  background-color: #44c8f5;
  color: #ffffff;
  padding: 50px;
  hr {
    border-color: #42b8e1;
  }
  &--gray {
    background: #f5f6f8;
    color: #2e2d2c;
    .ac-de-structure__link {
      color: #004182;
      text-decoration-color: #86a5c2;
      text-decoration-style: dotted;
      
    }
    .ac-de-structure__item {
      &:before {
        background-color: #ed2552;
      }
      &:hover {
        &:before {
          background-color: #ed2552;
        }
        .ac-de-structure__link {
          color: #ed2552;
        }
      }
    }
  }
}
.ac-de-structure__title {
  font-size: rem(34);
  font-weight: 700;
  margin-bottom: 20px;
}
.ac-de-structure__subtitle {
  font-size: rem(22);
  font-weight: 700;
  margin-bottom: 17px;
}
.ac-de-structure__list {
  list-style: none;
  margin: 0;
  padding: 0;

}
.ac-de-structure__item {
  position: relative;
  padding-left: 17px;
  margin: 10px 0;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 50%;
  }
  &--active, &:hover {
    &:before {
      background-color: #004282;
    }
    .ac-de-structure__link {
      color: #004282;
      text-decoration: none;
    }
  }
}
.ac-de-structure__link {
  color: #ffffff;
  text-decoration: underline;
  text-decoration-color: #8fdef9;
}

.ac-de-director {
  background-color: #f5f6f8;
  padding: 45px;
  overflow: hidden;
}
.ac-de-director__photo {
  width: 153px;
  margin-right: 42px;
  float: left;
}
.ac-de-director__info {
  overflow: hidden;
}
.ac-de-director__name {
  font-size: rem(18);
  font-weight: 700;
  margin: 10px 0;
}
.ac-de-director__post {

}

.ac-de-facts__tbl {
  width: 100%;
  td:first-child {
    text-align: right;
    padding-right: 20px;
  }
  td {
    font-size: rem(16);
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
.ac-de-facts__num {
  font-size: 34px;
  font-weight: 700;
  line-height: normal;
}

.ac-de-partners {
  border: 1px #e5e5e5 solid;
  padding: 0 50px 35px 50px;
  h3 {
    margin-bottom: 40px;
  }
}
.ac-de-partners__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.ac-de-partners__item {
  margin: 15px 0;
}
@media (max-width: 767px) {
  .ac-de-title {
    font-size: rem(28);
    line-height: 30px;
    span {
      font-size: rem(28);
    }
  }
  .ac-de-director__photo {
    float: none;
    margin: 0 auto 20px;
  }
  .ac-de-director__info {
    text-align: center;
  }
  .ac-de-structure {
    padding: 30px;
  }
}